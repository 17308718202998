/**
 * @generated SignedSource<<322f063a6e59e4c62a01fde15c413a4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ServiceRequestStatus = "CANCELLED" | "EXPIRED" | "FULFILLED" | "REQUIRES_PAYMENT" | "%future added value";
export type ServiceRequestType = "ACTIVATE_BILLED_SERVICE" | "ACTIVATE_SERVICE" | "DEACTIVATE_BILLED_SERVICE" | "DEACTIVATE_SERVICE" | "%future added value";
export type SubmitServiceRequestsInput = {
  serviceRequests: ReadonlyArray<ServiceRequestInput>;
};
export type ServiceRequestInput = {
  applianceId: string;
  priceId?: string | null;
  serviceId: string;
  type: ServiceRequestType;
};
export type Appliance_submitServiceRequests_Mutation$variables = {
  input: SubmitServiceRequestsInput;
};
export type Appliance_submitServiceRequests_Mutation$data = {
  readonly submitServiceRequests: {
    readonly serviceRequests: ReadonlyArray<{
      readonly appliance: {
        readonly id: string;
        readonly name: string;
      };
      readonly price: {
        readonly amount: number;
        readonly currency: string;
        readonly id: string;
        readonly interval: string | null;
        readonly intervalCount: number | null;
        readonly service: {
          readonly id: string;
          readonly prices: ReadonlyArray<{
            readonly id: string;
          }>;
        };
      } | null;
      readonly requestedAt: string;
      readonly service: {
        readonly description: string | null;
        readonly id: string;
        readonly name: string;
        readonly prices: ReadonlyArray<{
          readonly id: string;
        }>;
      };
      readonly status: ServiceRequestStatus;
      readonly type: ServiceRequestType;
      readonly updatedAt: string;
    }>;
  } | null;
};
export type Appliance_submitServiceRequests_Mutation = {
  response: Appliance_submitServiceRequests_Mutation$data;
  variables: Appliance_submitServiceRequests_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requestedAt",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "updatedAt",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "prices",
  "plural": true,
  "selections": [
    (v6/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Price",
  "kind": "LinkedField",
  "name": "price",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "amount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currency",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "interval",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "intervalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Service",
      "kind": "LinkedField",
      "name": "service",
      "plural": false,
      "selections": [
        (v6/*: any*/),
        (v7/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v9/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v7/*: any*/)
  ],
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Appliance",
  "kind": "LinkedField",
  "name": "appliance",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v9/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_submitServiceRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitServiceRequestsPayload",
        "kind": "LinkedField",
        "name": "submitServiceRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceRequest",
            "kind": "LinkedField",
            "name": "serviceRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliance_submitServiceRequests_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "SubmitServiceRequestsPayload",
        "kind": "LinkedField",
        "name": "submitServiceRequests",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ServiceRequest",
            "kind": "LinkedField",
            "name": "serviceRequests",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e901525a0c1866a72fe75a17f0980e",
    "id": null,
    "metadata": {},
    "name": "Appliance_submitServiceRequests_Mutation",
    "operationKind": "mutation",
    "text": "mutation Appliance_submitServiceRequests_Mutation(\n  $input: SubmitServiceRequestsInput!\n) {\n  submitServiceRequests(input: $input) {\n    serviceRequests {\n      type\n      status\n      requestedAt\n      updatedAt\n      price {\n        id\n        amount\n        currency\n        interval\n        intervalCount\n        service {\n          id\n          prices {\n            id\n          }\n        }\n      }\n      service {\n        id\n        name\n        description\n        prices {\n          id\n        }\n      }\n      appliance {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c241493ad20c57bb15936f1e34838638";

export default node;
