import { useMemo, useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type {
  ColumnDef,
  FilterFn,
  PaginationState,
  SortingState,
  VisibilityState,
} from "@tanstack/react-table";
import Table from "react-bootstrap/Table";
import _ from "lodash";

import Icon from "components/Icon";
import TablePagination from "components/TablePagination";

declare module "@tanstack/table-core" {
  interface ColumnMeta {
    className?: string;
  }
}

const defaultSearchFunction: FilterFn<any> = (row, columnId, value) => {
  const columnValue = row.getValue(columnId);
  return _.isString(columnValue) && columnValue.includes(value);
};

type SortDirectionIndicatorProps = {
  className?: string;
  descending: boolean;
};

const SortDirectionIndicator = ({
  className,
  descending,
}: SortDirectionIndicatorProps) => (
  <span className={className}>
    {descending ? <Icon icon="arrowDown" /> : <Icon icon="arrowUp" />}
  </span>
);

type StyledTableProps<T extends Object> = {
  columns: ColumnDef<T>[];
  data: T[];
  className?: string;
  maxPageRows?: number;
  hiddenColumns?: string[];
  searchFunction?: FilterFn<T>;
  searchText?: string;
};

const StyledTable = <T extends Object>({
  columns,
  data,
  className,
  hiddenColumns = [],
  maxPageRows = 10,
  searchFunction,
  searchText = "",
}: StyledTableProps<T>) => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: maxPageRows,
  });
  const [sorting, setSorting] = useState<SortingState>([]);

  const columnVisibility = useMemo(() => {
    let columnState: VisibilityState = {};
    hiddenColumns.forEach((c) => {
      columnState[c] = false;
    });
    return columnState;
  }, [hiddenColumns]);

  const table = useReactTable({
    data,
    columns,
    state: {
      columnVisibility,
      globalFilter: searchText,
      pagination,
      sorting,
    },
    globalFilterFn: searchFunction ? searchFunction : defaultSearchFunction,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,

    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
  });

  const pageCount = table.getPageCount();

  return (
    <div className={className}>
      <Table responsive hover>
        <thead className="border-top">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="d-none d-xl-table-row" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  {header.column.getIsSorted() && (
                    <SortDirectionIndicator
                      className="ms-2"
                      descending={header.column.getIsSorted() === "desc"}
                    />
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="border-top-0">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cell.column.columnDef.meta?.className}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
      <TablePagination
        totalPages={pageCount}
        activePage={pagination.pageIndex}
        onPageChange={(index) => table.setPageIndex(index)}
      />
    </div>
  );
};

export default StyledTable;
export type { ColumnDef as Column, FilterFn };
