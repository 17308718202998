import { FormattedMessage } from "react-intl";

import { Link, Route } from "Navigation";
import Tag from "components/Tag";
import StyledTable from "components/StyledTable";
import Stack from "components/Stack";
import type { Column } from "components/StyledTable";

interface Role {
  id: string;
  name: string;
}

interface User {
  id: string;
  name: string;
  roles: Role[];
  email: string;
}

interface Props {
  className?: string;
  data: User[];
  searchText?: string;
}

const columns: Column<User>[] = [
  {
    accessorKey: "name",
    header: () => (
      <FormattedMessage
        id="components.UsersTable.nameTitle"
        defaultMessage="Name"
        description="Title for the Name column of the users table"
      />
    ),
    cell: ({ row, getValue }) => (
      <Stack gap={2}>
        <Link route={Route.usersEdit} params={{ userId: row.original.id }}>
          {getValue()}
        </Link>
        <small className="d-xl-none">{row.original.email}</small>
      </Stack>
    ),
  },
  {
    accessorKey: "roles",
    header: () => (
      <FormattedMessage
        id="components.UsersTable.rolesTitle"
        defaultMessage="Roles"
        description="Title for the Roles column of the users table"
      />
    ),
    cell: ({ row, getValue }) => {
      const value = getValue() as Role[];
      return (
        <>
          {value.map((role) => (
            <Tag key={role.id} className="me-2">
              {role.name}
            </Tag>
          ))}
        </>
      );
    },
    meta: {
      className: "align-middle",
    },
  },
  {
    accessorKey: "email",
    header: () => (
      <FormattedMessage
        id="components.UsersTable.emailTitle"
        defaultMessage="Email"
        description="Title for the Email column of the users table"
      />
    ),
    meta: {
      className: "d-none d-xl-table-cell",
    },
  },
];

const UsersTable = ({ className, data, searchText }: Props) => (
  <StyledTable
    className={className}
    columns={columns}
    data={data}
    searchText={searchText}
  />
);

export default UsersTable;
