/**
 * @generated SignedSource<<7fe2563a72d854ddf2162f3d0089b979>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ServiceRequestStatus = "CANCELLED" | "EXPIRED" | "FULFILLED" | "REQUIRES_PAYMENT" | "%future added value";
export type ServiceRequestType = "ACTIVATE_BILLED_SERVICE" | "ACTIVATE_SERVICE" | "DEACTIVATE_BILLED_SERVICE" | "DEACTIVATE_SERVICE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ServiceRequestsTable_ServiceRequestsStatusFragment$data = ReadonlyArray<{
  readonly appliance: {
    readonly name: string;
    readonly serial: string;
    readonly tags: ReadonlyArray<string>;
  };
  readonly id: string;
  readonly paymentUrl: string | null;
  readonly price: {
    readonly amount: number;
    readonly currency: string;
    readonly interval: string | null;
    readonly intervalCount: number | null;
  } | null;
  readonly requestedAt: string;
  readonly service: {
    readonly name: string;
  };
  readonly status: ServiceRequestStatus;
  readonly type: ServiceRequestType;
  readonly " $fragmentType": "ServiceRequestsTable_ServiceRequestsStatusFragment";
}>;
export type ServiceRequestsTable_ServiceRequestsStatusFragment$key = ReadonlyArray<{
  readonly " $data"?: ServiceRequestsTable_ServiceRequestsStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ServiceRequestsTable_ServiceRequestsStatusFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ServiceRequestsTable_ServiceRequestsStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requestedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Service",
      "kind": "LinkedField",
      "name": "service",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intervalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Appliance",
      "kind": "LinkedField",
      "name": "appliance",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceRequest",
  "abstractKey": null
};
})();

(node as any).hash = "8bd3c947d00f100b3526b4826d18a7d7";

export default node;
