/**
 * @generated SignedSource<<9723e91842596bad433f795b5200f39d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Permission = "CAN_ADD_CLIENTS" | "CAN_ASSIGN_APPLIANCES" | "CAN_CREATE_APPLIANCES" | "CAN_CREATE_APPLIANCE_MODELS" | "CAN_CREATE_ROLES" | "CAN_DELETE_APPLIANCES" | "CAN_DELETE_APPLIANCE_MODELS" | "CAN_DELETE_ROLES" | "CAN_DELETE_USERS" | "CAN_EDIT_APPLIANCES" | "CAN_EDIT_APPLIANCE_MODELS" | "CAN_EDIT_ROLES" | "CAN_GET_APPLIANCES" | "CAN_GET_APPLIANCE_MODELS" | "CAN_GET_ROLES" | "CAN_GET_USERS" | "CAN_INVITE_USERS" | "CAN_LIST_APPLIANCES" | "CAN_LIST_APPLIANCE_MODELS" | "CAN_LIST_CLIENTS" | "CAN_LIST_ROLES" | "CAN_LIST_USERS" | "CAN_MANAGE_SERVICES_BILLING" | "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT" | "CAN_MANAGE_USER_ROLES" | "CAN_REMOVE_CLIENTS" | "%future added value";
export type CreateRoleInput = {
  role: RoleInput;
};
export type RoleInput = {
  name: string;
  permissions: ReadonlyArray<Permission>;
};
export type RoleCreate_createRole_Mutation$variables = {
  input: CreateRoleInput;
};
export type RoleCreate_createRole_Mutation$data = {
  readonly createRole: {
    readonly role: {
      readonly id: string;
      readonly name: string;
      readonly permissions: ReadonlyArray<Permission>;
    };
  } | null;
};
export type RoleCreate_createRole_Mutation = {
  response: RoleCreate_createRole_Mutation$data;
  variables: RoleCreate_createRole_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "CreateRolePayload",
    "kind": "LinkedField",
    "name": "createRole",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Role",
        "kind": "LinkedField",
        "name": "role",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "permissions",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RoleCreate_createRole_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RoleCreate_createRole_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "64228784156cffa5abd28e522e97e15e",
    "id": null,
    "metadata": {},
    "name": "RoleCreate_createRole_Mutation",
    "operationKind": "mutation",
    "text": "mutation RoleCreate_createRole_Mutation(\n  $input: CreateRoleInput!\n) {\n  createRole(input: $input) {\n    role {\n      id\n      name\n      permissions\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "eaf822dea861dca897d3307937ee48e5";

export default node;
