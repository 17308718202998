/**
 * @generated SignedSource<<fb838b69efbbbd3f9f4e924de597a685>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ServiceActivationStatus = "ACTIVE" | "REQUIRES_PAYMENT" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type Services_ServiceActivationsStatusFragment$data = ReadonlyArray<{
  readonly nextRenewalAt: string | null;
  readonly owned: boolean;
  readonly paymentUrl: string | null;
  readonly price: {
    readonly amount: number;
    readonly currency: string;
    readonly interval: string | null;
    readonly intervalCount: number | null;
  } | null;
  readonly status: ServiceActivationStatus;
  readonly " $fragmentType": "Services_ServiceActivationsStatusFragment";
}>;
export type Services_ServiceActivationsStatusFragment$key = ReadonlyArray<{
  readonly " $data"?: Services_ServiceActivationsStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"Services_ServiceActivationsStatusFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "Services_ServiceActivationsStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "nextRenewalAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "paymentUrl",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intervalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "ServiceActivation",
  "abstractKey": null
};

(node as any).hash = "23e72e10c94dcd6b2613755e7c4fbaa4";

export default node;
