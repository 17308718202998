/**
 * @generated SignedSource<<7872a3c078f984b2609df491da406d0b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Services_createStripePortalSession_Mutation$variables = {};
export type Services_createStripePortalSession_Mutation$data = {
  readonly createStripePortalSession: {
    readonly stripePortalUrl: string;
  } | null;
};
export type Services_createStripePortalSession_Mutation = {
  response: Services_createStripePortalSession_Mutation$data;
  variables: Services_createStripePortalSession_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CreateStripePortalSessionPayload",
    "kind": "LinkedField",
    "name": "createStripePortalSession",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "stripePortalUrl",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_createStripePortalSession_Mutation",
    "selections": (v0/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Services_createStripePortalSession_Mutation",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "17792690ef29ca57eb57046af56f053c",
    "id": null,
    "metadata": {},
    "name": "Services_createStripePortalSession_Mutation",
    "operationKind": "mutation",
    "text": "mutation Services_createStripePortalSession_Mutation {\n  createStripePortalSession {\n    stripePortalUrl\n  }\n}\n"
  }
};
})();

(node as any).hash = "8a6f360ff5362fc365aab8454cbb4732";

export default node;
