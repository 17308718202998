import React from "react";
import { useIntl } from "react-intl";
import Chart from "react-apexcharts";

interface Props {
  connected: number;
  disconnected: number;
  height: string;
}

const AppliancesChart = ({ connected, disconnected, height }: Props) => {
  const intl = useIntl();
  const chartOptions = {
    labels: [
      intl.formatMessage({
        id: "components.AppliancesChart.connectedLabel",
        defaultMessage: "Connected",
      }),
      intl.formatMessage({
        id: "components.AppliancesChart.disconnectedLabel",
        defaultMessage: "Disconnected",
      }),
    ],
    colors: ["var(--cp-color-connected)", "var(--cp-color-disconnected)"],
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: "bottom",
    } as const,
  };

  return (
    <Chart
      options={chartOptions}
      type="donut"
      width="100%"
      height={height}
      series={[connected, disconnected]}
    />
  );
};

export default React.memo(AppliancesChart);
