/**
 * @generated SignedSource<<4dfef8294ef0756e6928d4c329e25be2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AppliancesTable_ApplianceEdgeFragment$data = {
  readonly edges: ReadonlyArray<{
    readonly node: {
      readonly assignee?: {
        readonly name: string;
      } | null;
      readonly device: {
        readonly lastDisconnection: string | null;
        readonly online: boolean;
      };
      readonly id: string;
      readonly name: string;
      readonly serial: string;
      readonly tags: ReadonlyArray<string>;
    };
  }>;
  readonly " $fragmentType": "AppliancesTable_ApplianceEdgeFragment";
};
export type AppliancesTable_ApplianceEdgeFragment$key = {
  readonly " $data"?: AppliancesTable_ApplianceEdgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppliancesTable_ApplianceEdgeFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": false,
      "kind": "LocalArgument",
      "name": "withAssignee"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppliancesTable_ApplianceEdgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ApplianceEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Appliance",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "serial",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "tags",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Device",
              "kind": "LinkedField",
              "name": "device",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "lastDisconnection",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "online",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "condition": "withAssignee",
              "kind": "Condition",
              "passingValue": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "assignee",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ]
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ApplianceConnection",
  "abstractKey": null
};
})();

(node as any).hash = "99f0a6126193d2124973ed9980de25c1";

export default node;
