import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Collapse from "react-bootstrap/Collapse";

import Button from "components/Button";
import Icon from "components/Icon";
import Stack from "components/Stack";

type Props = {
  children?: React.ReactNode;
  className?: string;
  collapsible?: boolean;
  title?: React.ReactNode;
};

const SectionCard = ({
  children,
  className = "",
  collapsible = false,
  title,
}: Props) => {
  const [collapsed, setCollapsed] = useState(false);
  const cardClassName = ["h-100 border-0 shadow-sm p-3 p-md-4", className].join(
    " "
  );
  return (
    <Card className={cardClassName}>
      <Stack gap={3}>
        <div className="d-flex align-items-center">
          {title && <h6 className="text-primary">{title}</h6>}
          {collapsible && (
            <Button
              variant="text-primary"
              className="ms-auto"
              aria-expanded={!collapsed}
              onClick={() => setCollapsed(!collapsed)}
            >
              <Icon icon={collapsed ? "caretDown" : "caretUp"} />
            </Button>
          )}
        </div>
        <Collapse in={!collapsed}>
          <Stack gap={3}>{children}</Stack>
        </Collapse>
      </Stack>
    </Card>
  );
};

export default SectionCard;
