import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import Table from "react-bootstrap/Table";

import InfiniteScroll from "components/InfiniteScroll";

declare module "@tanstack/table-core" {
  interface ColumnMeta {
    className?: string;
  }
}

type InfiniteTableProps<T extends Object> = {
  columns: ColumnDef<T>[];
  data: T[];
  className?: string;
  loading?: boolean;
  onLoadMore?: () => void;
};

const InfiniteTable = <T extends Object>({
  columns,
  data,
  className = "",
  loading = false,
  onLoadMore,
}: InfiniteTableProps<T>) => {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <InfiniteScroll
      className={className}
      loading={loading}
      onLoadMore={onLoadMore}
    >
      <Table hover>
        <thead className="border-top bg-white position-sticky top-0">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr className="d-none d-xl-table-row" key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  colSpan={header.colSpan}
                  onClick={header.column.getToggleSortingHandler()}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="border-top-0">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className={cell.column.columnDef.meta?.className}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </InfiniteScroll>
  );
};

export default InfiniteTable;
export type { ColumnDef as Column };
