import { useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import { useFragment } from "react-relay/hooks";
import { FormattedDate, FormattedMessage } from "react-intl";

import type {
  AppliancesTable_ApplianceEdgeFragment$key,
  AppliancesTable_ApplianceEdgeFragment$data,
} from "api/__generated__/AppliancesTable_ApplianceEdgeFragment.graphql";

import InfiniteTable from "components/InfiniteTable";
import type { Column } from "components/InfiniteTable";
import { Link, Route } from "Navigation";
import ConnectionStatus from "components/ConnectionStatus";
import Stack from "components/Stack";
import Tag from "components/Tag";
import "./AppliancesTable.scss";

const APPLIANCES_TABLE_FRAGMENT = graphql`
  fragment AppliancesTable_ApplianceEdgeFragment on ApplianceConnection
  @argumentDefinitions(
    withAssignee: { type: "Boolean!", defaultValue: false }
  ) {
    edges {
      node {
        id
        name
        serial
        tags
        device {
          lastDisconnection
          online
        }
        assignee @include(if: $withAssignee) {
          name
        }
      }
    }
  }
`;

type Appliance = AppliancesTable_ApplianceEdgeFragment$data["edges"][number]["node"];

const getColumnsDefinition = (withAssignee: boolean): Column<Appliance>[] => {
  const columns: Column<Appliance>[] = [
    {
      id: "status",
      accessorFn: (appliance) => Boolean(appliance.device.online),
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.statusTitle"
          defaultMessage="Status"
          description="Title for the Status column of the appliances table"
        />
      ),
      cell: ({ getValue }) => {
        const value = getValue() as boolean;
        return <ConnectionStatus connected={value} />;
      },
      meta: {
        className: "align-middle",
      },
    },
    {
      accessorKey: "name",
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.nameTitle"
          defaultMessage="Appliance Name"
          description="Title for the Name column of the appliances table"
        />
      ),
      cell: ({ row, getValue }) => (
        <Stack gap={2}>
          <Link
            route={Route.appliancesEdit}
            params={{ applianceId: row.original.id }}
          >
            {getValue()}
          </Link>
          <small className="d-xl-none">{row.original.serial}</small>
          {withAssignee && (
            <small className="d-xl-none">{row.original.assignee?.name}</small>
          )}
        </Stack>
      ),
      meta: {
        className: "align-middle column-name",
      },
    },
    {
      accessorKey: "serial",
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.serialTitle"
          defaultMessage="Appliance S/N"
          description="Title for the Serial column of the appliances table"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "assignee",
      accessorFn: (appliance) => appliance.assignee?.name,
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.assigneeTitle"
          defaultMessage="Assignee"
          description="Title for the Assignee column of the appliances table"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "lastSeen",
      accessorFn: (appliance): string => {
        if (appliance.device.online) {
          return "now";
        } else {
          return appliance.device.lastDisconnection || "never";
        }
      },
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.lastSeenTitle"
          defaultMessage="Last Seen"
          description="Title for the Last Seen column of the appliances table"
        />
      ),
      cell: ({ getValue }) => {
        const value = getValue() as string;
        if (value === "now") {
          return (
            <FormattedMessage
              id="components.AppliancesTable.lastSeen.now"
              defaultMessage="Now"
              description="Label in the LastSeen column for an appliance that is online"
            />
          );
        } else if (value === "never") {
          return (
            <FormattedMessage
              id="components.AppliancesTable.lastSeen.never"
              defaultMessage="Never"
              description="Label in the LastSeen column for an appliance that never connected"
            />
          );
        } else {
          return (
            <FormattedDate
              value={new Date(value)}
              year="numeric"
              month="long"
              day="numeric"
              hour="numeric"
              minute="numeric"
            />
          );
        }
      },
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "tags",
      // React table v8 doesn't filter columns with array type.
      // This will be no longer necessary once server side filters are implemented.
      accessorFn: (appliance) => appliance.tags.join(","),
      enableSorting: false,
      header: () => (
        <FormattedMessage
          id="components.AppliancesTable.tagsTitle"
          defaultMessage="Tags"
          description="Title for the Tags column of the appliances table"
        />
      ),
      cell: ({ row }) => {
        const value = row.original.tags;
        return (
          <>
            {value.map((tag) => (
              <Tag key={tag} className="me-2">
                {tag}
              </Tag>
            ))}
          </>
        );
      },
      meta: {
        className: "align-middle",
      },
    },
  ];

  if (withAssignee) {
    return columns;
  }
  return columns.filter((column) => column.id !== "assignee");
};

interface Props {
  className?: string;
  appliancesRef?: AppliancesTable_ApplianceEdgeFragment$key;
  withAssignee?: boolean;
  loading?: boolean;
  onLoadMore?: () => void;
}

const AppliancesTable = ({
  className,
  appliancesRef,
  withAssignee = false,
  loading = false,
  onLoadMore,
}: Props) => {
  const appliancesFragment = useFragment(
    APPLIANCES_TABLE_FRAGMENT,
    appliancesRef || null
  );
  const appliances = useMemo(() => {
    if (appliancesFragment) {
      return appliancesFragment.edges.map(({ node }) => node);
    }
    return [];
  }, [appliancesFragment]);

  const columns = useMemo(() => getColumnsDefinition(withAssignee), [
    withAssignee,
  ]);

  return (
    <InfiniteTable
      className={className}
      columns={columns}
      data={appliances}
      loading={loading}
      onLoadMore={onLoadMore}
    />
  );
};

export default AppliancesTable;
