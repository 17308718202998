import React, {
  Suspense,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
  usePaginationFragment,
} from "react-relay/hooks";
import Card from "react-bootstrap/Card";
import _ from "lodash";

import type {
  Appliances_getAppliances_Query,
  Appliances_getAppliances_Query$data,
} from "api/__generated__/Appliances_getAppliances_Query.graphql";
import type { Appliances_PaginationQuery } from "api/__generated__/Appliances_PaginationQuery.graphql";
import type { Appliances_AppliancesFragment$key } from "api/__generated__/Appliances_AppliancesFragment.graphql";
import * as images from "assets/images";
import { Link, Route } from "Navigation";
import AppliancesChart from "components/AppliancesChart";
import AppliancesTable from "components/AppliancesTable";
import { useCanOneOf } from "components/Can";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import Result from "components/Result";
import ResourceCounter from "components/ResourceCounter";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import { useTenantConfig } from "contexts/TenantConfig";
import "./Appliances.scss";

const GET_APPLIANCES_QUERY = graphql`
  query Appliances_getAppliances_Query(
    $first: Int!
    $after: String
    $showClients: Boolean!
    $filter: ApplianceFilter
  ) {
    ...Appliances_AppliancesFragment
    applianceStats {
      connected
      disconnected
      total
    }
  }
`;

const APPLIANCES_TO_LOAD_FIRST = 40;
const APPLIANCES_TO_LOAD_NEXT = 10;

const APPLIANCES_FRAGMENT = graphql`
  fragment Appliances_AppliancesFragment on RootQueryType
  @refetchable(queryName: "Appliances_PaginationQuery") {
    appliances(first: $first, after: $after, filter: $filter)
      @connection(
        key: "Appliances_appliances"
        filters: ["filter", "showClients"]
      ) {
      edges {
        node {
          __typename
        }
      }
      ...AppliancesTable_ApplianceEdgeFragment
        @arguments(withAssignee: $showClients)
    }
  }
`;

type AppliancesSidebarProps = {
  applianceStats?: Appliances_getAppliances_Query$data["applianceStats"];
};

const AppliancesSidebar = ({ applianceStats }: AppliancesSidebarProps) => (
  <Stack gap={5} className="mt-3 p-3">
    {applianceStats ? (
      <>
        {applianceStats.total > 0 ? (
          <AppliancesChart
            connected={applianceStats.connected}
            disconnected={applianceStats.disconnected}
            height="200"
          />
        ) : (
          <Image src={images.devices} />
        )}
        <ResourceCounter
          resource={
            <FormattedMessage
              id="pages.Appliances.connectedAppliancesCounter.resource"
              defaultMessage="Connected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
              values={{ count: applianceStats.connected }}
            />
          }
          count={applianceStats.connected}
        />
        <ResourceCounter
          resource={
            <FormattedMessage
              id="pages.Appliances.disconnectedAppliancesCounter.resource"
              defaultMessage="Disconnected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
              values={{ count: applianceStats.disconnected }}
            />
          }
          count={applianceStats.disconnected}
        />
      </>
    ) : (
      <Image src={images.devices} />
    )}
  </Stack>
);

interface AppliancesTableContainerProps {
  appliancesData: Appliances_getAppliances_Query["response"];
  searchText: string | null;
  showClients: boolean;
}

const AppliancesTableContainer = ({
  appliancesData,
  searchText,
  showClients,
}: AppliancesTableContainerProps) => {
  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment<
    Appliances_PaginationQuery,
    Appliances_AppliancesFragment$key
  >(APPLIANCES_FRAGMENT, appliancesData);

  const debounceRefetch = useMemo(
    () =>
      _.debounce((searchText: string) => {
        if (searchText === "") {
          return refetch(
            {
              first: APPLIANCES_TO_LOAD_FIRST,
              showClients,
            },
            { fetchPolicy: "network-only" }
          );
        }
        refetch(
          {
            first: APPLIANCES_TO_LOAD_FIRST,
            showClients,
            filter: { matching: searchText },
          },
          { fetchPolicy: "network-only" }
        );
      }, 500),
    [refetch, showClients]
  );

  useEffect(() => {
    if (searchText !== null) {
      debounceRefetch(searchText);
    }
  }, [debounceRefetch, searchText]);

  const loadNextAppliances = useCallback(() => {
    if (hasNext && !isLoadingNext) {
      loadNext(APPLIANCES_TO_LOAD_NEXT);
    }
  }, [hasNext, isLoadingNext, loadNext]);

  const appliancesRef = data?.appliances || null;

  if (!appliancesRef) {
    return null;
  }

  return (
    <AppliancesTable
      appliancesRef={appliancesRef}
      withAssignee={showClients}
      loading={isLoadingNext}
      onLoadMore={hasNext ? loadNextAppliances : undefined}
    />
  );
};

interface AppliancesContentProps {
  getAppliancesQuery: PreloadedQuery<Appliances_getAppliances_Query>;
  showClients: boolean;
}

const AppliancesContent = ({
  getAppliancesQuery,
  showClients,
}: AppliancesContentProps) => {
  const [searchText, setSearchText] = useState<string | null>(null);
  const { deviceClaimInsteadOfApplianceRegistration } = useTenantConfig();
  const appliancesData = usePreloadedQuery(
    GET_APPLIANCES_QUERY,
    getAppliancesQuery
  );
  const { applianceStats } = appliancesData;

  if (applianceStats?.total === 0) {
    return (
      <>
        <Result.Empty
          title={
            <FormattedMessage
              id="pages.Appliances.noAppliances.title"
              defaultMessage="There are no appliances yet."
            />
          }
        >
          {deviceClaimInsteadOfApplianceRegistration ? (
            <Link route={Route.devicesClaim}>
              <FormattedMessage
                id="pages.Appliances.noAppliances.claimApplianceLink"
                defaultMessage="Claim an appliance"
              />
            </Link>
          ) : (
            <Link route={Route.appliancesNew}>
              <FormattedMessage
                id="pages.Appliances.noAppliances.registerApplianceLink"
                defaultMessage="Register an appliance"
              />
            </Link>
          )}
        </Result.Empty>
        <SidebarContent>
          <AppliancesSidebar applianceStats={applianceStats} />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox
          className="d-none d-xl-block"
          value={searchText || ""}
          onChange={setSearchText}
        />
      </PageToolbar>
      {applianceStats && (
        <Card className="d-flex flex-row d-xl-none shadow p-3 mb-4">
          <div className="graph-container">
            <AppliancesChart
              connected={applianceStats.connected}
              disconnected={applianceStats.disconnected}
              height="180"
            />
          </div>
          <div className="d-flex flex-column justify-content-around px-2">
            <ResourceCounter
              resource={
                <FormattedMessage
                  id="pages.Appliances.connectedAppliancesCounter.resource"
                  defaultMessage="Connected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
                  values={{ count: applianceStats.connected }}
                />
              }
              count={applianceStats.connected}
              direction="horizontal"
            />
            <ResourceCounter
              resource={
                <FormattedMessage
                  id="pages.Appliances.disconnectedAppliancesCounter.resource"
                  defaultMessage="Disconnected {count, plural, =0 {appliances} one {appliance} other {appliances}}"
                  values={{ count: applianceStats.disconnected }}
                />
              }
              count={applianceStats.disconnected}
              direction="horizontal"
            />
          </div>
        </Card>
      )}
      <SearchBox
        className="d-xl-none mb-2"
        value={searchText || ""}
        onChange={setSearchText}
      />
      <Suspense
        fallback={<AppliancesTable withAssignee={showClients} loading={true} />}
      >
        <AppliancesTableContainer
          showClients={showClients}
          appliancesData={appliancesData}
          searchText={searchText}
        />
      </Suspense>
      <SidebarContent>
        <AppliancesSidebar applianceStats={applianceStats} />
      </SidebarContent>
    </>
  );
};

const Appliances = () => {
  const [
    getAppliancesQuery,
    getAppliances,
  ] = useQueryLoader<Appliances_getAppliances_Query>(GET_APPLIANCES_QUERY);

  const canShowClients = useCanOneOf(["CAN_LIST_CLIENTS"]);

  useEffect(() => {
    getAppliances(
      {
        first: APPLIANCES_TO_LOAD_FIRST,
        showClients: canShowClients,
      },
      { fetchPolicy: "network-only" }
    );
  }, [getAppliances, canShowClients]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Appliances.title"
          defaultMessage="Appliance List"
          description="Title for the Appliances page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <AppliancesSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <AppliancesSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getAppliances(
              {
                first: APPLIANCES_TO_LOAD_FIRST,
                showClients: canShowClients,
              },
              { fetchPolicy: "network-only" }
            );
          }}
        >
          {getAppliancesQuery && (
            <AppliancesContent
              getAppliancesQuery={getAppliancesQuery}
              showClients={canShowClients}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Appliances;
