import { useEffect, useRef } from "react";

import Spinner from "components/Spinner";

// Set flex: 1 for the container together with a fixed flex-basis.
// This will ensure the container will fill the available height of its parent
// but also trigger an overflow condition if it spans more height.
// The overflow can then be handled with an overflow: auto to hide excess
// content and display the scroll bar.
// For more details see: https://stackoverflow.com/a/52489012
const containerStyle = { flex: "1 1 1px" };

type Props = {
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
  onLoadMore?: () => void;
};

const InfiniteScroll = ({
  children,
  className,
  loading = false,
  onLoadMore,
}: Props) => {
  const sentinelRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const sentinel = sentinelRef.current;
    if (!onLoadMore || !sentinel || !window.IntersectionObserver) {
      return;
    }
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        onLoadMore();
      }
    });
    observer.observe(sentinel);
    return () => observer.disconnect();
  }, [sentinelRef, onLoadMore]);

  return (
    <div className={`flex-grow-1 d-flex flex-column ${className}`}>
      <div className="overflow-auto" style={containerStyle}>
        {children}
        {(loading || onLoadMore) && (
          <div className="text-center" ref={sentinelRef}>
            <Spinner />
          </div>
        )}
      </div>
    </div>
  );
};

export default InfiniteScroll;
