import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";
import type {
  ApplianceModelsTable_ApplianceModels$data,
  ApplianceModelsTable_ApplianceModels$key,
} from "api/__generated__/ApplianceModelsTable_ApplianceModels.graphql";

import Stack from "components/Stack";
import StyledTable, { Column, FilterFn } from "components/StyledTable";
import { Link, Route } from "Navigation";

const APPLIANCE_MODELS_TABLE_FRAGMENT = graphql`
  fragment ApplianceModelsTable_ApplianceModels on ApplianceModel
  @relay(plural: true) {
    id
    handle
    names {
      text
    }
    partNumbers
  }
`;

type ApplianceModelProps = ApplianceModelsTable_ApplianceModels$data[number];

const columns: Column<ApplianceModelProps>[] = [
  {
    accessorKey: "names",
    header: () => (
      <FormattedMessage
        id="components.ApplianceModelsTable.nameTitle"
        defaultMessage="Model Name"
      />
    ),
    cell: ({ row, getValue }) => {
      const name = getValue<ApplianceModelProps["names"]>()[0]?.text;
      const handle = row.original.handle;
      const label = name || handle;
      return (
        <Stack>
          <Link
            route={Route.applianceModelsEdit}
            params={{ applianceModelId: row.original.id }}
          >
            <span className="text-nowrap">{label}</span>
          </Link>
          <span className="d-xl-none text-nowrap">
            <small>{handle}</small>
          </span>
        </Stack>
      );
    },
  },
  {
    accessorKey: "handle",
    header: () => (
      <FormattedMessage
        id="components.ApplianceModelsTable.handleTitle"
        defaultMessage="Handle"
      />
    ),
    cell: ({ getValue }) => <span className="text-nowrap">{getValue()}</span>,
    meta: {
      className: "d-none d-xl-table-cell",
    },
  },
  {
    accessorKey: "partNumbers",
    enableSorting: false,
    header: () => (
      <FormattedMessage
        id="components.ApplianceModelsTable.partNumbersTitle"
        defaultMessage="Part Numbers"
      />
    ),
    cell: ({ getValue }) =>
      getValue<ApplianceModelProps["partNumbers"]>().map(
        (partNumber, index) => (
          <React.Fragment key={partNumber}>
            {index > 0 && ", "}
            <span className="text-nowrap">{partNumber}</span>
          </React.Fragment>
        )
      ),
    meta: {
      className: "d-none d-xl-table-cell",
    },
  },
];

const searchFunction: FilterFn<ApplianceModelProps> = (
  row,
  columnId,
  value
) => {
  const applianceModel = row.original;
  return (
    applianceModel.handle.includes(value) ||
    applianceModel.names.some((name) => name.text.includes(value)) ||
    applianceModel.partNumbers.some((partNumber) => partNumber.includes(value))
  );
};

interface Props {
  applianceModelsRef: ApplianceModelsTable_ApplianceModels$key;
  className?: string;
  searchText?: string;
}

const ApplianceModelsTable = ({
  applianceModelsRef,
  className,
  searchText,
}: Props) => {
  const applianceModelsData = useFragment(
    APPLIANCE_MODELS_TABLE_FRAGMENT,
    applianceModelsRef
  );
  // TODO: handle readonly type without mapping to mutable type
  const applianceModels = useMemo(
    () => applianceModelsData.map((model) => ({ ...model })),
    [applianceModelsData]
  );

  return (
    <StyledTable
      className={className}
      columns={columns}
      data={applianceModels}
      searchText={searchText}
      searchFunction={searchFunction}
    />
  );
};

export default ApplianceModelsTable;
