/**
 * @generated SignedSource<<5afb99b10db4d0a07a2ccbbf13e43921>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ApplianceFilter = {
  assigned?: boolean | null;
  matching?: string | null;
  tag?: string | null;
};
export type Appliances_PaginationQuery$variables = {
  after?: string | null;
  filter?: ApplianceFilter | null;
  first?: number | null;
  showClients: boolean;
};
export type Appliances_PaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Appliances_AppliancesFragment">;
};
export type Appliances_PaginationQuery = {
  response: Appliances_PaginationQuery$data;
  variables: Appliances_PaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "after"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "filter"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "first"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showClients"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "filter",
    "variableName": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliances_PaginationQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Appliances_AppliancesFragment"
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliances_PaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ApplianceConnection",
        "kind": "LinkedField",
        "name": "appliances",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplianceEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Appliance",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "serial",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tags",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Device",
                    "kind": "LinkedField",
                    "name": "device",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "lastDisconnection",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "online",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "condition": "showClients",
                    "kind": "Condition",
                    "passingValue": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "assignee",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ]
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "filter",
          "showClients"
        ],
        "handle": "connection",
        "key": "Appliances_appliances",
        "kind": "LinkedHandle",
        "name": "appliances"
      }
    ]
  },
  "params": {
    "cacheID": "af06d67b3764c4fed0a2a85255b5751a",
    "id": null,
    "metadata": {},
    "name": "Appliances_PaginationQuery",
    "operationKind": "query",
    "text": "query Appliances_PaginationQuery(\n  $after: String\n  $filter: ApplianceFilter\n  $first: Int\n  $showClients: Boolean!\n) {\n  ...Appliances_AppliancesFragment\n}\n\nfragment AppliancesTable_ApplianceEdgeFragment_39pXqJ on ApplianceConnection {\n  edges {\n    node {\n      id\n      name\n      serial\n      tags\n      device {\n        lastDisconnection\n        online\n        id\n      }\n      assignee @include(if: $showClients) {\n        name\n        id\n      }\n    }\n  }\n}\n\nfragment Appliances_AppliancesFragment on RootQueryType {\n  appliances(first: $first, after: $after, filter: $filter) {\n    edges {\n      node {\n        __typename\n        id\n      }\n      cursor\n    }\n    ...AppliancesTable_ApplianceEdgeFragment_39pXqJ\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2e6deece156bdb7e3c7fb2e5122318bf";

export default node;
