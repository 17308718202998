/**
 * @generated SignedSource<<40374442f6a118fa93776303e9e70fa8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Services_GetServiceActivations_Query$variables = {};
export type Services_GetServiceActivations_Query$data = {
  readonly serviceActivations: ReadonlyArray<{
    readonly appliance: {
      readonly id: string;
      readonly name: string;
    };
    readonly id: string;
    readonly price: {
      readonly id: string;
    } | null;
    readonly service: {
      readonly id: string;
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ActiveServicesTable_ServiceActivationsStatusFragment" | "Services_ServiceActivationsStatusFragment">;
  }> | null;
};
export type Services_GetServiceActivations_Query = {
  response: Services_GetServiceActivations_Query$data;
  variables: Services_GetServiceActivations_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Service",
  "kind": "LinkedField",
  "name": "service",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_GetServiceActivations_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceActivation",
        "kind": "LinkedField",
        "name": "serviceActivations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Appliance",
            "kind": "LinkedField",
            "name": "appliance",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "price",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ActiveServicesTable_ServiceActivationsStatusFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "Services_ServiceActivationsStatusFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Services_GetServiceActivations_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceActivation",
        "kind": "LinkedField",
        "name": "serviceActivations",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Appliance",
            "kind": "LinkedField",
            "name": "appliance",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "price",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "__typename",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "intervalCount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "activatedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "owned",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nextRenewalAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4c2c765515f031a86426b2d68d3dcd1d",
    "id": null,
    "metadata": {},
    "name": "Services_GetServiceActivations_Query",
    "operationKind": "query",
    "text": "query Services_GetServiceActivations_Query {\n  serviceActivations {\n    id\n    appliance {\n      id\n      name\n    }\n    service {\n      id\n      name\n    }\n    price {\n      id\n    }\n    ...ActiveServicesTable_ServiceActivationsStatusFragment\n    ...Services_ServiceActivationsStatusFragment\n  }\n}\n\nfragment ActiveServicesTable_ServiceActivationsStatusFragment on ServiceActivation {\n  id\n  activatedAt\n  owned\n  service {\n    name\n    id\n  }\n  price {\n    __typename\n    id\n  }\n  appliance {\n    name\n    serial\n    tags\n    id\n  }\n}\n\nfragment Services_ServiceActivationsStatusFragment on ServiceActivation {\n  nextRenewalAt\n  owned\n  paymentUrl\n  price {\n    amount\n    currency\n    interval\n    intervalCount\n    id\n  }\n  status\n}\n"
  }
};
})();

(node as any).hash = "da678ad7855ad0f045a4dab5b90d4b17";

export default node;
