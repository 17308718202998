import { FormattedMessage, FormattedNumber } from "react-intl";

const ZERO_DECIMAL_CURRENCIES = [
  "BIF",
  "CLP",
  "DJF",
  "GNF",
  "JPY",
  "KMF",
  "KRW",
  "MGA",
  "PYG",
  "RWF",
  "UGX",
  "VND",
  "VUV",
  "XAF",
  "XOF",
  "XPF",
];

const THREE_DECIMAL_CURRENCIES = ["BHD", "JOD", "KWD", "OMR", "TND"];

function roundAmountToCurrencyDecimals(
  amount: number,
  currency: string
): number {
  if (ZERO_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    return Math.round(amount);
  }
  if (THREE_DECIMAL_CURRENCIES.includes(currency.toUpperCase())) {
    return Math.round(amount) / 1000;
  }
  return Math.round(amount) / 100;
}

type Props = {
  amount: number;
  currency: string;
  interval?: string | null;
  intervalCount?: number | null;
};

const Price = (props: Props) => {
  const currency = props.currency;
  const amount = roundAmountToCurrencyDecimals(props.amount, currency);
  const intervalCount = props.intervalCount || 1;

  const formattedAmount = (
    <FormattedNumber value={amount} style="currency" currency={currency} />
  );

  switch (props.interval) {
    case "day":
      return (
        <span className="text-nowrap">
          <FormattedMessage
            id="components.Price.dailyAmount"
            defaultMessage="{amount} {intervalCount, plural, =1 {/ day} other {/ # days}}"
            values={{ amount: formattedAmount, intervalCount }}
          />
        </span>
      );
    case "week":
      return (
        <span className="text-nowrap">
          <FormattedMessage
            id="components.Price.weeklyAmount"
            defaultMessage="{amount} {intervalCount, plural, =1 {/ week} other {/ # weeks}}"
            values={{ amount: formattedAmount, intervalCount }}
          />
        </span>
      );
    case "month":
      return (
        <span className="text-nowrap">
          <FormattedMessage
            id="components.Price.monthlyAmount"
            defaultMessage="{amount} {intervalCount, plural, =1 {/ month} other {/ # months}}"
            values={{ amount: formattedAmount, intervalCount }}
          />
        </span>
      );
    case "year":
      return (
        <span className="text-nowrap">
          <FormattedMessage
            id="components.Price.yearlyAmount"
            defaultMessage="{amount} {intervalCount, plural, =1 {/ year} other {/ # years}}"
            values={{ amount: formattedAmount, intervalCount }}
          />
        </span>
      );
    default:
      return <span className="text-nowrap">{formattedAmount}</span>;
  }
};

export default Price;
