import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import graphql from "babel-plugin-relay/macro";
import {
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  useSubscription,
  PreloadedQuery,
} from "react-relay/hooks";
import { FormattedMessage, useIntl } from "react-intl";
import Alert, { AlertProps } from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import _ from "lodash";

import * as images from "assets/images";
import type { Appliance_provisioningStatus$key } from "api/__generated__/Appliance_provisioningStatus.graphql";
import type { Appliance_getAppliance_Query } from "api/__generated__/Appliance_getAppliance_Query.graphql";
import type { Appliance_getTags_Query } from "api/__generated__/Appliance_getTags_Query.graphql";
import type {
  Appliance_submitServiceRequests_Mutation,
  ServiceRequestInput,
} from "api/__generated__/Appliance_submitServiceRequests_Mutation.graphql";
import type { Appliance_updateAppliance_Mutation } from "api/__generated__/Appliance_updateAppliance_Mutation.graphql";
import { Link, Route } from "Navigation";
import ApplianceModelDetails from "components/ApplianceModelDetails";
import ApplianceOperations from "components/ApplianceOperations";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import ConnectionStatus from "components/ConnectionStatus";
import Center from "components/Center";
import ClientAssignee from "components/ClientAssignee";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import MultiSelect from "components/MultiSelect";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import ApplianceApp, { isSupportedApp } from "components/ApplianceApp";
import SectionCard from "components/SectionCard";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tabs, { Tab } from "components/Tabs";
import Tag from "components/Tag";
import UnavailableApplianceApp from "components/UnavailableApplianceApp";
import { useTenantConfig } from "contexts/TenantConfig";

const APPLIANCE_PROVISIONING_STATUS_FRAGMENT = graphql`
  fragment Appliance_provisioningStatus on Device {
    provisioningStatus {
      osCodeId
      osVersion
    }
  }
`;

const APPLIANCE_APPLIANCEUPDATED_SUBSCRIPTION = graphql`
  subscription Appliance_applianceUpdated_Subscription($ids: [ID!]!) {
    applianceUpdated(ids: $ids) {
      id
      name
      serial
      tags
      assignee {
        id
        name
      }
      device {
        online
      }
    }
  }
`;

interface ApplianceProvisioningStatusProps {
  deviceRef: Appliance_provisioningStatus$key;
}

const ApplianceProvisioningStatus = ({
  deviceRef,
}: ApplianceProvisioningStatusProps) => {
  const { provisioningStatus } = useFragment(
    APPLIANCE_PROVISIONING_STATUS_FRAGMENT,
    deviceRef
  );
  if (!provisioningStatus) {
    return null;
  }
  return (
    <Stack gap={3}>
      {provisioningStatus.osCodeId != null && (
        <Form.Group controlId="form-appliance-os">
          <Form.Label>
            <FormattedMessage
              id="Appliance.operativeSystem"
              defaultMessage="OS"
              description="Label for the appliance operative system"
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={provisioningStatus.osCodeId}
            readOnly
            plaintext
          />
        </Form.Group>
      )}
      {provisioningStatus.osVersion != null && (
        <Form.Group controlId="form-appliance-os-version">
          <Form.Label>
            <FormattedMessage
              id="Appliance.operativeSystemVersion"
              defaultMessage="OS version"
              description="Label for the appliance operative system version"
            />
          </Form.Label>
          <Form.Control
            type="text"
            value={provisioningStatus.osVersion}
            readOnly
            plaintext
          />
        </Form.Group>
      )}
    </Stack>
  );
};

// TODO: Use specific appliance query of fragment instead of filtering the appliance list
const GET_APPLIANCE_QUERY = graphql`
  query Appliance_getAppliance_Query($id: ID!) {
    appliance(id: $id) {
      id
      name
      serial
      partNumber
      tags
      device {
        deviceId
        realm
        baseApiUrl
        online
        ...Appliance_provisioningStatus
      }
      availableApplications {
        application {
          id
          protocol
          displayName
          sourceUrl
        }
        astarteCredentials {
          authToken
        }
      }
      unavailableApplications {
        id
        displayName
        protocol
        requiresAnyOfServices {
          ...UnavailableApplianceApp_ServicesFragment
        }
      }
      model {
        pictureUrl
        ...ApplianceModelDetails
      }
      assignee {
        id
        name
      }
      ...ApplianceOperations_applianceId
      ...ClientAssignee_applianceAssignee
    }
  }
`;

const GET_TAGS_QUERY = graphql`
  query Appliance_getTags_Query {
    existingApplianceTags
  }
`;

const UPDATE_APPLIANCE_MUTATION = graphql`
  mutation Appliance_updateAppliance_Mutation($input: UpdateApplianceInput!) {
    updateAppliance(input: $input) {
      appliance {
        id
        name
        tags
      }
    }
  }
`;

const SUBMIT_SERVICE_REQUESTS_MUTATION = graphql`
  mutation Appliance_submitServiceRequests_Mutation(
    $input: SubmitServiceRequestsInput!
  ) {
    submitServiceRequests(input: $input) {
      serviceRequests {
        type
        status
        requestedAt
        updatedAt
        price {
          id
          amount
          currency
          interval
          intervalCount
          service {
            id
            prices {
              id
            }
          }
        }
        service {
          id
          name
          description
          prices {
            id
          }
        }
        appliance {
          id
          name
        }
      }
    }
  }
`;

type ApplianceSidebarProps = {
  appliance?: Appliance_getAppliance_Query["response"]["appliance"];
};

const ApplianceSidebar = ({ appliance }: ApplianceSidebarProps) => {
  if (!appliance) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image src={images.devices} />
      </Stack>
    );
  }
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <div>
        <h4>{appliance.name}</h4>
        <p className="text-muted">{appliance.serial}</p>
      </div>
      <Image
        src={appliance.model?.pictureUrl || undefined}
        fallbackSrc={images.devices}
      />
      <Center>
        <ConnectionStatus connected={appliance.device.online} />
      </Center>
      <div>
        {appliance.tags.map((tag) => (
          <Tag className="mb-1 me-1" key={tag}>
            {tag}
          </Tag>
        ))}
      </div>
      {appliance.assignee && (
        <>
          <hr />
          {appliance.assignee.name}
        </>
      )}
    </Stack>
  );
};

interface ApplianceContentProps {
  getApplianceQuery: PreloadedQuery<Appliance_getAppliance_Query>;
  getTagsQuery: PreloadedQuery<Appliance_getTags_Query>;
  refreshAvailableApplications: () => void;
  refreshTags: () => void;
}

const ApplianceContent = ({
  getApplianceQuery,
  getTagsQuery,
  refreshAvailableApplications,
  refreshTags,
}: ApplianceContentProps) => {
  const { applianceId = "" } = useParams();
  const intl = useIntl();
  const { paymentsEnabled } = useTenantConfig();
  const canEditAppliances = useCanOneOf(["CAN_EDIT_APPLIANCES"]);
  const canManageServicesBilling =
    useCanOneOf(["CAN_MANAGE_SERVICES_BILLING"]) && paymentsEnabled;
  const canManageServicesWithoutPayment = useCanOneOf([
    "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
  ]);
  const canActivateServices =
    canManageServicesBilling || canManageServicesWithoutPayment;
  const applianceData = usePreloadedQuery(
    GET_APPLIANCE_QUERY,
    getApplianceQuery
  );
  const tagsData = usePreloadedQuery(GET_TAGS_QUERY, getTagsQuery);
  const [updateAppliance] = useMutation<Appliance_updateAppliance_Mutation>(
    UPDATE_APPLIANCE_MUTATION
  );
  const [
    submitServiceRequests,
    isSubmittingServiceRequests,
  ] = useMutation<Appliance_submitServiceRequests_Mutation>(
    SUBMIT_SERVICE_REQUESTS_MUTATION
  );

  // TODO: handle readonly type without mapping to mutable type
  const appliance = useMemo(
    () =>
      applianceData.appliance && {
        ...applianceData.appliance,
        tags: applianceData.appliance.tags.slice(),
        device: {
          ...applianceData.appliance.device,
        },
      },
    [applianceData.appliance]
  );

  const tags = useMemo(
    () =>
      tagsData.existingApplianceTags
        ? tagsData.existingApplianceTags.slice()
        : [],
    [tagsData.existingApplianceTags]
  );

  const config = useMemo(
    () => ({
      variables: { ids: [appliance?.id] },
      subscription: APPLIANCE_APPLIANCEUPDATED_SUBSCRIPTION,
    }),
    [appliance?.id]
  );
  useSubscription(config);

  const availableApplications = useMemo(
    () =>
      applianceData.appliance?.availableApplications.filter(({ application }) =>
        isSupportedApp(application)
      ) ?? [],
    [applianceData.appliance?.availableApplications]
  );

  const applicationProps = useMemo(
    () =>
      appliance
        ? availableApplications.map(({ application, astarteCredentials }) => ({
            appId: application.id,
            appUrl: new URL(application.sourceUrl),
            appDisplayName: application.displayName,
            appProps: {
              astarteUrl: new URL(appliance.device.baseApiUrl),
              realm: appliance.device.realm,
              deviceId: appliance.device.deviceId,
              token: astarteCredentials.authToken,
            },
          }))
        : [],
    // Split each individual prop to make sure memoization happens on the actual values used by the remote app
    [
      availableApplications,
      appliance?.device.baseApiUrl,
      appliance?.device.deviceId,
      appliance?.device.realm,
    ]
  );

  const unavailableApplications =
    applianceData.appliance && canActivateServices
      ? applianceData.appliance.unavailableApplications.filter(isSupportedApp)
      : [];

  const [applianceDraft, setApplianceDraft] = useState(
    _.pick(appliance, ["name", "tags"])
  );
  const [
    updateErrorFeedback,
    setUpdateErrorFeedback,
  ] = useState<React.ReactNode>(null);
  const [
    submitServiceRequestsFeedback,
    setSubmitServiceRequestsFeedback,
  ] = useState<null | {
    alertVariant: AlertProps["variant"];
    feedback: React.ReactNode;
  }>(null);

  const handleUpdateAppliance = useMemo(
    () =>
      _.debounce(
        (
          draft: typeof applianceDraft,
          applianceChanges: Partial<typeof applianceDraft>
        ) => {
          updateAppliance({
            variables: { input: { applianceId, ...applianceChanges } },
            onCompleted(data, errors) {
              if (errors) {
                setApplianceDraft(draft);
                const updateErrorFeedback = errors
                  .map((error) => error.message)
                  .join(". \n");
                return setUpdateErrorFeedback(updateErrorFeedback);
              }
              if (applianceChanges.tags != null) {
                refreshTags();
              }
            },
            onError(error) {
              setApplianceDraft(draft);
              setUpdateErrorFeedback(
                <FormattedMessage
                  id="pages.Appliance.updateApplianceErrorFeedback"
                  defaultMessage="Could not update the appliance, please try again."
                  description="Feedback for unknown error while updating an appliance"
                />
              );
            },
          });
        },
        500,
        { leading: true }
      ),
    [updateAppliance, applianceId, refreshTags]
  );

  const handleApplianceChange = useCallback(
    (applianceChanges: Partial<typeof applianceDraft>) => {
      setApplianceDraft((draft) => ({ ...draft, ...applianceChanges }));
      handleUpdateAppliance(applianceDraft, applianceChanges);
    },
    [handleUpdateAppliance, applianceDraft]
  );

  const handleApplianceTagsChange = useCallback(
    (tags: string[]) => {
      handleApplianceChange({
        tags: tags.map((tag) => tag.trim().toLowerCase()),
      });
    },
    [handleApplianceChange]
  );

  const handleApplianceTagCreate = useCallback(
    (tag: string) => {
      const tags = (applianceDraft.tags || []).concat(tag.trim().toLowerCase());
      handleApplianceChange({ tags });
    },
    [handleApplianceChange, applianceDraft.tags]
  );

  const handleSubmitServiceRequests = useCallback(
    (serviceRequests: ServiceRequestInput[]) => {
      submitServiceRequests({
        variables: { input: { serviceRequests } },
        onCompleted(data, errors) {
          if (errors) {
            const feedback = errors
              .map((error) => error.message)
              .join(". \n") as React.ReactNode;
            return setSubmitServiceRequestsFeedback({
              feedback,
              alertVariant: "danger",
            });
          }
          const serviceRequests =
            data.submitServiceRequests?.serviceRequests || [];

          let feedbacks: React.ReactNode[] = [];
          let alertVariant: AlertProps["variant"] = "success";

          feedbacks.push(
            <FormattedMessage
              id="pages.Appliance.submitServiceRequestsCompletedFeedback"
              defaultMessage="The service requests were processed."
              values={{
                link: (chunks: React.ReactNode) => (
                  <Link route={Route.services}>{chunks}</Link>
                ),
              }}
            />
          );
          if (
            serviceRequests.some(
              (request) => request.status === "REQUIRES_PAYMENT"
            )
          ) {
            alertVariant = "warning";
            feedbacks.push(
              <FormattedMessage
                id="pages.Appliance.submitServiceRequestsMissingPaymentFeedback"
                defaultMessage="A payment is missing, please review pending payments in the <link>Services page</link>."
                values={{
                  link: (chunks: React.ReactNode) => (
                    <Link route={Route.services}>{chunks}</Link>
                  ),
                }}
              />
            );
          }
          if (
            serviceRequests.some((request) => request.status === "FULFILLED")
          ) {
            feedbacks.push(
              <FormattedMessage
                id="pages.Appliance.submitServiceRequestsFulfilledFeedback"
                defaultMessage="Some features were activated, <link>click here</link> to refresh the page."
                values={{
                  link: (chunks: React.ReactNode) => (
                    <Button
                      variant="link"
                      onClick={() => {
                        setSubmitServiceRequestsFeedback(null);
                        refreshAvailableApplications();
                      }}
                    >
                      {chunks}
                    </Button>
                  ),
                }}
              />
            );
          }
          const feedback = feedbacks.reduce(
            (acc, feedback) => (
              <>
                {acc}&nbsp;{feedback}
              </>
            ),
            <></>
          );
          setSubmitServiceRequestsFeedback({ feedback, alertVariant });
        },
        onError(error) {
          setSubmitServiceRequestsFeedback({
            feedback: (
              <FormattedMessage
                id="pages.Appliance.submitServiceRequestsErrorFeedback"
                defaultMessage="Could not submit the service request, please try again."
                description="Feedback for unknown error while submitting a service request"
              />
            ),
            alertVariant: "danger",
          });
        },
        updater(store, data) {
          if (!data.submitServiceRequests) {
            return;
          }
          const root = store.getRoot();
          const oldRequestRecords = root.getLinkedRecords("serviceRequests");
          const newRequestRecords = store
            .getRootField("submitServiceRequests")
            .getLinkedRecords("serviceRequests");
          if (oldRequestRecords) {
            // Invalidate existing service requests
            oldRequestRecords.forEach((record) => record.invalidateRecord());
            root.setLinkedRecords(
              [...newRequestRecords, ...oldRequestRecords],
              "serviceRequests"
            );
          }

          const oldActivationRecords = root.getLinkedRecords(
            "serviceActivations"
          );
          if (oldActivationRecords) {
            // Invalidate existing service activations
            oldActivationRecords.forEach((record) => record.invalidateRecord());
          }

          if (
            data.submitServiceRequests.serviceRequests.some(
              (request) => request.status === "FULFILLED"
            )
          ) {
            // Invalidate availableApplications / unavailableApplications of the appliance
            store
              .get(applianceId)
              ?.getLinkedRecords("availableApplications")
              ?.forEach((record) => record.invalidateRecord());
            store
              .get(applianceId)
              ?.getLinkedRecords("unavailableApplications")
              ?.forEach((record) => record.invalidateRecord());
          }
        },
      });
    },
    [submitServiceRequests, refreshAvailableApplications, applianceId]
  );

  if (!applianceData.appliance || !appliance) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.appliance.applianceNotFound.title"
              defaultMessage="Appliance not found."
              description="Page title for an appliance not found"
            />
          }
        >
          <Link route={Route.appliances}>
            <FormattedMessage
              id="pages.appliance.applianceNotFound.message"
              defaultMessage="Return to the appliance list"
              description="Page message for an appliance not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <ApplianceSidebar />
        </SidebarContent>
      </>
    );
  }

  const availableApplicationsIds = availableApplications.map(
    ({ application }) => application.id
  );
  const unavailableApplicationsIds = unavailableApplications.map(
    (application) => application.id
  );
  const tabKeys = availableApplicationsIds.concat(unavailableApplicationsIds, [
    "appliance-details",
    "model-details",
  ]);
  const defaultTabKey = tabKeys[0];

  return (
    <>
      <Alert
        show={!!updateErrorFeedback}
        variant="danger"
        onClose={() => setUpdateErrorFeedback(null)}
        dismissible
      >
        {updateErrorFeedback}
      </Alert>
      <Alert
        show={!!submitServiceRequestsFeedback}
        variant={submitServiceRequestsFeedback?.alertVariant}
        onClose={() => setSubmitServiceRequestsFeedback(null)}
        dismissible
      >
        {submitServiceRequestsFeedback?.feedback}
      </Alert>
      <Tabs tabsOrder={tabKeys} defaultActiveKey={defaultTabKey}>
        <Tab
          eventKey="appliance-details"
          title={intl.formatMessage({
            id: "pages.appliance.applianceDetails",
            defaultMessage: "Appliance Details",
          })}
          data-testid="tab-appliance-details"
          className="mt-4"
        >
          <Stack gap={4}>
            <Form>
              <Row xs={1} lg={2} className="g-4">
                <Col>
                  <SectionCard
                    title={
                      <FormattedMessage
                        id="pages.appliance.applianceInfo"
                        defaultMessage="Appliance Info"
                      />
                    }
                  >
                    <Form.Group controlId="form-appliance-name">
                      <Form.Label>
                        <FormattedMessage
                          id="Appliance.name"
                          defaultMessage="Name"
                          description="Label for the appliance name"
                        />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={applianceDraft.name}
                        readOnly={!canEditAppliances}
                        plaintext={!canEditAppliances}
                        onChange={(event) => {
                          handleApplianceChange({
                            name: event.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group controlId="form-appliance-tags">
                      <Form.Label>
                        <FormattedMessage
                          id="Appliance.tags"
                          defaultMessage="Tags"
                          description="Label for the appliance tags"
                        />
                      </Form.Label>
                      <MultiSelect
                        id="form-appliance-tags"
                        selected={applianceDraft.tags || []}
                        values={tags}
                        getValueId={(tag) => tag}
                        getValueLabel={(tag) => tag}
                        onChange={handleApplianceTagsChange}
                        onCreateValue={handleApplianceTagCreate}
                        disabled={!canEditAppliances}
                      />
                    </Form.Group>
                    <ApplianceProvisioningStatus deviceRef={appliance.device} />
                  </SectionCard>
                </Col>
                <Col>
                  <SectionCard
                    title={
                      <FormattedMessage
                        id="pages.appliance.applianceIdentifiers"
                        defaultMessage="Appliance Identifiers"
                      />
                    }
                  >
                    <Form.Group controlId="form-appliance-serial">
                      <Form.Label>
                        <FormattedMessage
                          id="Appliance.serialNumber"
                          defaultMessage="Appliance S/N"
                          description="Label for the appliance serial number"
                        />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={appliance.serial}
                        readOnly
                        plaintext
                      />
                    </Form.Group>
                    {appliance.partNumber && (
                      <Form.Group controlId="form-appliance-partNumber">
                        <Form.Label>
                          <FormattedMessage
                            id="Appliance.partNumber"
                            defaultMessage="Appliance P/N"
                            description="Label for the appliance part number"
                          />
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value={appliance.partNumber}
                          readOnly
                          plaintext
                        />
                      </Form.Group>
                    )}
                    <Form.Group controlId="form-appliance-deviceId">
                      <Form.Label>
                        <FormattedMessage
                          id="Appliance.deviceId"
                          defaultMessage="Device ID"
                          description="Label for the Device ID of an appliance"
                        />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        value={appliance.device.deviceId}
                        readOnly
                        plaintext
                      />
                    </Form.Group>
                  </SectionCard>
                </Col>
              </Row>
            </Form>
            <ClientAssignee applianceRef={applianceData.appliance} />
            <ApplianceOperations applianceRef={applianceData.appliance} />
          </Stack>
        </Tab>
        {appliance.model && (
          <Tab
            eventKey="model-details"
            title={intl.formatMessage({
              id: "pages.appliance.modelDetails",
              defaultMessage: "Model Details",
            })}
            data-testid="tab-model-details"
            className="mt-4"
          >
            <ApplianceModelDetails applianceModelRef={appliance.model} />
          </Tab>
        )}
        {applicationProps.map((props) => (
          <Tab
            key={props.appId}
            eventKey={props.appId}
            title={props.appDisplayName}
          >
            <ApplianceApp
              appId={props.appId}
              appUrl={props.appUrl}
              appProps={props.appProps}
            />
          </Tab>
        ))}
        {unavailableApplications.map((app) => (
          <Tab key={app.id} eventKey={app.id} title={app.displayName}>
            <UnavailableApplianceApp
              className="mt-2"
              servicesRef={app.requiresAnyOfServices}
              onServiceRequest={(serviceRequest) =>
                handleSubmitServiceRequests([
                  { ...serviceRequest, applianceId },
                ])
              }
              isSubmittingRequest={isSubmittingServiceRequests}
            />
          </Tab>
        ))}
      </Tabs>
      <SidebarContent>
        <ApplianceSidebar appliance={appliance} />
      </SidebarContent>
    </>
  );
};

const Appliance = () => {
  const { applianceId = "" } = useParams();

  const [
    getApplianceQuery,
    getAppliance,
  ] = useQueryLoader<Appliance_getAppliance_Query>(GET_APPLIANCE_QUERY);

  const [getTagsQuery, getTags] = useQueryLoader<Appliance_getTags_Query>(
    GET_TAGS_QUERY
  );

  const refreshTags = useCallback(
    () => getTags({}, { fetchPolicy: "store-and-network" }),
    [getTags]
  );

  const refreshAppliance = useCallback(
    () => getAppliance({ id: applianceId }),
    [getAppliance, applianceId]
  );

  useEffect(() => {
    refreshAppliance();
    refreshTags();
  }, [refreshAppliance, refreshTags]);

  return (
    <Page>
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplianceSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplianceSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            refreshAppliance();
          }}
        >
          {getApplianceQuery && getTagsQuery && (
            <ApplianceContent
              getApplianceQuery={getApplianceQuery}
              getTagsQuery={getTagsQuery}
              refreshAvailableApplications={refreshAppliance}
              refreshTags={refreshTags}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Appliance;
