import graphql from "babel-plugin-relay/macro";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useFragment } from "react-relay/hooks";

import {
  ServiceRequestsTable_ServiceRequestsStatusFragment$data,
  ServiceRequestsTable_ServiceRequestsStatusFragment$key,
} from "api/__generated__/ServiceRequestsTable_ServiceRequestsStatusFragment.graphql";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Price from "components/Price";
import Stack from "components/Stack";
import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";
import Tag from "components/Tag";
import { useTenantConfig } from "contexts/TenantConfig";

/* eslint-disable relay/unused-fields */
const SERVICE_REQUESTS_STATUS_FRAGMENT = graphql`
  fragment ServiceRequestsTable_ServiceRequestsStatusFragment on ServiceRequest
  @relay(plural: true) {
    id
    type
    status
    requestedAt
    paymentUrl
    service {
      name
    }
    price {
      amount
      currency
      interval
      intervalCount
    }
    appliance {
      name
      serial
      tags
    }
  }
`;

type Row = ServiceRequestsTable_ServiceRequestsStatusFragment$data[0];

type CancelRequestHandler = (id: string) => void;

type ServiceRequestTypeProps = {
  serviceRequest: Row;
};

const ServiceRequestType = ({ serviceRequest }: ServiceRequestTypeProps) => {
  const { type, price } = serviceRequest;
  switch (type) {
    case "ACTIVATE_SERVICE": {
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.type.activateService"
          defaultMessage="Add"
        />
      );
    }
    case "DEACTIVATE_SERVICE": {
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.type.deactivateService"
          defaultMessage="Remove"
        />
      );
    }
    case "ACTIVATE_BILLED_SERVICE": {
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.type.activateBilledService"
          defaultMessage="Add for {price}"
          values={{
            price: price && (
              <Price
                amount={price.amount}
                currency={price.currency}
                interval={price.interval}
                intervalCount={price.intervalCount}
              />
            ),
          }}
        />
      );
    }
    case "DEACTIVATE_BILLED_SERVICE": {
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.type.deactivateBilledService"
          defaultMessage="Remove {price}"
          values={{
            price: price && (
              <Price
                amount={price.amount}
                currency={price.currency}
                interval={price.interval}
                intervalCount={price.intervalCount}
              />
            ),
          }}
        />
      );
    }
    default: {
      return <span>-</span>;
    }
  }
};

type ServiceRequestStatusProps = {
  serviceRequest: Row;
};

const ServiceRequestStatus = ({
  serviceRequest,
}: ServiceRequestStatusProps) => {
  const { status, paymentUrl } = serviceRequest;
  switch (status) {
    case "FULFILLED":
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.statusFulfilled"
          defaultMessage="Fulfilled"
        />
      );
    case "CANCELLED":
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.statusCancelled"
          defaultMessage="Cancelled"
        />
      );
    case "EXPIRED":
      return (
        <FormattedMessage
          id="components.ServiceRequestsTable.statusExpired"
          defaultMessage="Expired"
        />
      );
    case "REQUIRES_PAYMENT":
      return (
        <Button variant="outline-primary" as="a" href={paymentUrl || ""}>
          <FormattedMessage
            id="components.ServiceRequestsTable.statusRequiresPaymentButton"
            defaultMessage="Missing Payment"
          />
        </Button>
      );
    default:
      return <span>-</span>;
  }
};

type CancelRequestButtonProps = {
  onCancel: CancelRequestHandler;
  serviceRequest: Row;
};

const CancelRequestButton = ({
  onCancel,
  serviceRequest,
}: CancelRequestButtonProps) => {
  const { paymentsEnabled } = useTenantConfig();
  const canManageServicesBilling =
    useCanOneOf(["CAN_MANAGE_SERVICES_BILLING"]) && paymentsEnabled;
  const canManageServicesWithoutPayment = useCanOneOf([
    "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
  ]);
  if (
    ["ACTIVATE_SERVICE", "DEACTIVATE_SERVICE"].includes(serviceRequest.type) &&
    !canManageServicesWithoutPayment
  ) {
    return null;
  }
  if (
    ["ACTIVATE_BILLED_SERVICE", "DEACTIVATE_BILLED_SERVICE"].includes(
      serviceRequest.type
    ) &&
    !canManageServicesBilling
  ) {
    return null;
  }
  if (["CANCELLED", "EXPIRED", "FULFILLED"].includes(serviceRequest.status)) {
    return null;
  }
  return (
    <Button
      variant="outline-danger"
      onClick={() => onCancel(serviceRequest.id)}
    >
      <FormattedMessage
        id="components.ServiceRequestsTable.cancelServiceRequest"
        defaultMessage="Cancel"
      />
    </Button>
  );
};

const useColumns: (onCancel: CancelRequestHandler) => Column<Row>[] = (
  onCancel
) => {
  return [
    {
      id: "applianceName",
      accessorFn: (row) => row.appliance.name,
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.applianceNameTitle"
          defaultMessage="Appliance Name"
        />
      ),
      cell: ({ row }) => (
        <Stack gap={2} className="py-2 py-xl-0">
          <Stack gap={0}>
            <span className="text-nowrap">{row.original.appliance.name}</span>
            <span className="d-xl-none text-muted text-nowrap">
              <small>{row.original.appliance.serial}</small>
            </span>
          </Stack>
          <Stack gap={0} className="d-xl-none">
            <span className="text-nowrap">{row.original.service.name}</span>
            <span className="text-muted text-nowrap">
              <small>
                <ServiceRequestType serviceRequest={row.original} />
              </small>
            </span>
            <span className="text-muted text-nowrap">
              <small>
                <FormattedMessage
                  id="components.ServiceRequestsTable.requestedAtTitle"
                  defaultMessage="Requested At"
                />
                &nbsp;
                <FormattedDate
                  value={row.original.requestedAt}
                  year="numeric"
                  month="long"
                  day="numeric"
                />
              </small>
            </span>
          </Stack>
          <div className="d-xl-none d-flex flex-column flex-xl-row">
            <ServiceRequestStatus serviceRequest={row.original} />
          </div>
          <div className="d-xl-none d-flex flex-column flex-xl-row">
            <CancelRequestButton
              serviceRequest={row.original}
              onCancel={onCancel}
            />
          </div>
        </Stack>
      ),
    },
    {
      id: "applianceSerialNumber",
      accessorFn: (row) => row.appliance.serial,
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.serialTitle"
          defaultMessage="Appliance S/N"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "applianceTags",
      accessorFn: (row) => row.appliance.tags,
      enableSorting: false,
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.tagsTitle"
          defaultMessage="Tags"
        />
      ),
      cell: ({ getValue }) => {
        const value = getValue() as string[];
        return (
          <>
            {value.map((tag) => (
              <Tag key={tag} className="me-2">
                {tag}
              </Tag>
            ))}
          </>
        );
      },
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "serviceName",
      accessorFn: (row) => row.service.name,
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.serviceNameTitle"
          defaultMessage="Service"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "type",
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.requestTypeTitle"
          defaultMessage="Requested Change"
        />
      ),
      cell: ({ row }) => <ServiceRequestType serviceRequest={row.original} />,
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "requestedAt",
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.requestedAtTitle"
          defaultMessage="Requested At"
        />
      ),
      cell: ({ getValue }) => {
        const date = getValue() as string;
        return (
          <FormattedDate
            value={date}
            year="numeric"
            month="long"
            day="numeric"
          />
        );
      },
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "status",
      header: () => (
        <FormattedMessage
          id="components.ServiceRequestsTable.statusTitle"
          defaultMessage="Status"
        />
      ),
      cell: ({ row }) => <ServiceRequestStatus serviceRequest={row.original} />,
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "id",
      header: () => null,
      enableSorting: false,
      enableGlobalFilter: false,
      cell: ({ row }) => (
        <CancelRequestButton
          serviceRequest={row.original}
          onCancel={onCancel}
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
  ];
};

type Props = {
  className?: string;
  serviceRequestsRef: ServiceRequestsTable_ServiceRequestsStatusFragment$key;
  searchText?: string;
  onCancel: CancelRequestHandler;
};

const ServiceRequestsTable = ({
  className,
  serviceRequestsRef,
  searchText,
  onCancel,
}: Props) => {
  const serviceRequests = useFragment(
    SERVICE_REQUESTS_STATUS_FRAGMENT,
    serviceRequestsRef
  ).filter((serviceRequest) => serviceRequest.status === "REQUIRES_PAYMENT");

  const columns = useColumns(onCancel);

  if (serviceRequests.length === 0) {
    return (
      <FormattedMessage
        id="components.ServiceRequestsTable.noServiceRequestsFeedback"
        defaultMessage="There are no pending changes."
      />
    );
  }

  return (
    <StyledTable
      className={className}
      columns={columns}
      data={serviceRequests}
      searchText={searchText}
    />
  );
};

export default ServiceRequestsTable;
