import { Suspense, useCallback, useEffect, useState } from "react";
import { FormattedDate, FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useFragment,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
  UseMutationConfig,
} from "react-relay/hooks";

import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

import * as images from "assets/images";
import { Link, Route } from "Navigation";
import type { Services_cancelServiceRequests_Mutation } from "api/__generated__/Services_cancelServiceRequests_Mutation.graphql";
import type { Services_GetServiceActivations_Query } from "api/__generated__/Services_GetServiceActivations_Query.graphql";
import type { Services_GetServiceRequests_Query } from "api/__generated__/Services_GetServiceRequests_Query.graphql";
import type { Services_GetServices_Query } from "api/__generated__/Services_GetServices_Query.graphql";
import type { Services_createStripePortalSession_Mutation } from "api/__generated__/Services_createStripePortalSession_Mutation.graphql";
import type { Services_getViewer_Query } from "api/__generated__/Services_getViewer_Query.graphql";
import type { Services_ServiceActivationsStatusFragment$key } from "api/__generated__/Services_ServiceActivationsStatusFragment.graphql";
import type { Services_submitServiceRequests_Mutation } from "api/__generated__/Services_submitServiceRequests_Mutation.graphql";
import ActiveServicesTable from "components/ActiveServicesTable";
import Button from "components/Button";
import Can, { useCanOneOf } from "components/Can";
import DeleteModal from "components/DeleteModal";
import Image from "components/Image";
import ErrorBoundary from "components/ErrorBoundary";
import Result from "components/Result";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Price from "components/Price";
import SectionCard from "components/SectionCard";
import ServiceRequestsTable from "components/ServiceRequestsTable";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import Tag from "components/Tag";
import { useTenantConfig } from "contexts/TenantConfig";

const GET_VIEWER_QUERY = graphql`
  query Services_getViewer_Query {
    viewer {
      id
      name
      email
      organization {
        id
        name
        thin
      }
      roles {
        id
        name
      }
    }
  }
`;

const GET_SERVICE_ACTIVATIONS_QUERY = graphql`
  query Services_GetServiceActivations_Query {
    serviceActivations {
      id
      appliance {
        id
        name
      }
      service {
        id
        name
      }
      price {
        id
      }
      ...ActiveServicesTable_ServiceActivationsStatusFragment
      ...Services_ServiceActivationsStatusFragment
    }
  }
`;

const GET_SERVICE_REQUESTS_QUERY = graphql`
  query Services_GetServiceRequests_Query {
    serviceRequests {
      id
      service {
        name
      }
      appliance {
        name
      }
      ...ServiceRequestsTable_ServiceRequestsStatusFragment
    }
  }
`;

const GET_SERVICES_QUERY = graphql`
  query Services_GetServices_Query {
    services {
      __typename
      prices {
        __typename
      }
    }
  }
`;

const CREATE_STRIPE_PORTAL_SESSION_MUTATION = graphql`
  mutation Services_createStripePortalSession_Mutation {
    createStripePortalSession {
      stripePortalUrl
    }
  }
`;

const SERVICE_ACTIVATIONS_STATUS_FRAGMENT = graphql`
  fragment Services_ServiceActivationsStatusFragment on ServiceActivation
  @relay(plural: true) {
    nextRenewalAt
    owned
    paymentUrl
    price {
      amount
      currency
      interval
      intervalCount
    }
    status
  }
`;

const SUBMIT_SERVICE_REQUESTS_MUTATION = graphql`
  mutation Services_submitServiceRequests_Mutation(
    $input: SubmitServiceRequestsInput!
  ) {
    submitServiceRequests(input: $input) {
      serviceRequests {
        id
        status
      }
    }
  }
`;

const CANCEL_SERVICE_REQUEST_MUTATION = graphql`
  mutation Services_cancelServiceRequests_Mutation(
    $input: CancelServiceRequestsInput!
  ) {
    cancelServiceRequests(input: $input) {
      serviceRequests {
        id
        status
      }
    }
  }
`;

type BillingSummaryCardProps = {
  serviceActivationsRef: Services_ServiceActivationsStatusFragment$key;
};

const BillingSummaryCard = ({
  serviceActivationsRef,
}: BillingSummaryCardProps) => {
  const serviceActivations = useFragment(
    SERVICE_ACTIVATIONS_STATUS_FRAGMENT,
    serviceActivationsRef
  ).filter((serviceActivation) => serviceActivation.owned);
  let totalAmount = 0;
  let currency = null;
  let interval = null;
  let intervalCount = null;
  let nextRenewal = null;
  let requiresPayments = false;
  let stripePaymentUrl = null;

  for (let i = 0; i < serviceActivations.length; i++) {
    const { price, nextRenewalAt, status, paymentUrl } = serviceActivations[i];

    if (price) {
      totalAmount += price.amount;
      currency = price.currency; // TODO handle multiple currencies
      interval = price.interval; // TODO handle different intervals
      intervalCount = price.intervalCount;
    }
    if (nextRenewalAt) {
      nextRenewal = nextRenewalAt; // TODO handle multiple renewals
    }
    if (status === "REQUIRES_PAYMENT") {
      requiresPayments = true;
    }
    if (paymentUrl) {
      stripePaymentUrl = paymentUrl;
    }
  }

  if (serviceActivations.length === 0) {
    return (
      <SectionCard
        title={
          <FormattedMessage
            id="pages.Services.servicesStatusCard.title"
            defaultMessage="Payment Status"
          />
        }
      >
        <div>
          <FormattedMessage
            id="pages.Services.servicesStatusCard.noServiceActivations"
            defaultMessage="There are no active recurring payments."
          />
        </div>
      </SectionCard>
    );
  }

  return (
    <SectionCard
      title={
        <FormattedMessage
          id="pages.Services.servicesStatusCard.title"
          defaultMessage="Payment Status"
        />
      }
    >
      <Stack gap={1}>
        <div>
          <b>
            <FormattedMessage
              id="pages.Services.servicesStatusCard.paymentStatus"
              defaultMessage="Payment Status"
            />
            :&nbsp;
          </b>
          {requiresPayments ? (
            <FormattedMessage
              id="pages.Services.servicesStatusCard.paymentRequired"
              defaultMessage="Payment Required"
            />
          ) : (
            <FormattedMessage
              id="pages.Services.servicesStatusCard.upToDate"
              defaultMessage="Up to Date"
            />
          )}
        </div>
        {nextRenewal && (
          <div>
            <b>
              <FormattedMessage
                id="pages.Services.servicesStatusCard.nextRenewal"
                defaultMessage="Next Renewal"
              />
              :&nbsp;
            </b>
            <FormattedDate
              value={nextRenewal}
              year="numeric"
              month="long"
              day="numeric"
            />
          </div>
        )}
        {currency && (
          <div>
            <b>
              <FormattedMessage
                id="pages.Services.servicesStatusCard.totalAmount"
                defaultMessage="Total Amount"
              />
              :&nbsp;
            </b>
            <Price
              amount={totalAmount}
              currency={currency}
              interval={interval}
              intervalCount={intervalCount}
            />
          </div>
        )}
      </Stack>
      {stripePaymentUrl && (
        <a href={stripePaymentUrl} className="btn btn-primary mt-3 me-auto">
          <FormattedMessage
            id="pages.Services.servicesStatusCard.retryPaymentButton"
            defaultMessage="Retry Payment"
          />
        </a>
      )}
    </SectionCard>
  );
};

const EmptyServiceSidebar = () => (
  <Stack gap={3} className="mt-3 p-3">
    <Image fallbackSrc={images.profile} />
  </Stack>
);

type ServicesSidebarProps = {
  getViewerQuery: PreloadedQuery<Services_getViewer_Query>;
};

const ServicesSidebar = ({ getViewerQuery }: ServicesSidebarProps) => {
  const viewerData = usePreloadedQuery(GET_VIEWER_QUERY, getViewerQuery);
  const user = viewerData.viewer;

  if (!user) {
    return <EmptyServiceSidebar />;
  }

  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <div>
        <h4>{user.name}</h4>
        <p className="text-muted">{user.email}</p>
      </div>
      <Image fallbackSrc={images.profile} />
      <Can oneOf={["CAN_LIST_ROLES"]}>
        <div>
          {user.roles.map((role) => (
            <Tag className="mb-1 me-1" key={role.id}>
              {role.name}
            </Tag>
          ))}
        </div>
      </Can>
      {!user.organization.thin && (
        <>
          <hr />
          {user.organization.name}
        </>
      )}
    </Stack>
  );
};

type ServiceContentProps = {
  getServiceActivationsQuery: PreloadedQuery<Services_GetServiceActivations_Query>;
  getServiceRequestsQuery: PreloadedQuery<Services_GetServiceRequests_Query>;
  getServicesQuery: PreloadedQuery<Services_GetServices_Query>;
  onServiceActivationsUpdated: () => void;
};

const ServicesContent = ({
  getServiceActivationsQuery,
  getServiceRequestsQuery,
  getServicesQuery,
  onServiceActivationsUpdated,
}: ServiceContentProps) => {
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [serviceRequestIdToCancel, setServiceRequestIdToCancel] = useState<
    string | null
  >(null);
  const [
    serviceActivationIdToDeactivate,
    setServiceActivationIdToDeactivate,
  ] = useState<string | null>(null);
  const { paymentsEnabled } = useTenantConfig();
  const canManageServicesBilling =
    useCanOneOf(["CAN_MANAGE_SERVICES_BILLING"]) && paymentsEnabled;
  const serviceActivationsData = usePreloadedQuery(
    GET_SERVICE_ACTIVATIONS_QUERY,
    getServiceActivationsQuery
  );
  const serviceRequestsData = usePreloadedQuery(
    GET_SERVICE_REQUESTS_QUERY,
    getServiceRequestsQuery
  );
  const servicesData = usePreloadedQuery(GET_SERVICES_QUERY, getServicesQuery);

  const [
    getPaymentMethodsStripeURL,
    isGettingPaymentMethodsStripeURL,
  ] = useMutation<Services_createStripePortalSession_Mutation>(
    CREATE_STRIPE_PORTAL_SESSION_MUTATION
  );

  const [
    getInvoiceHistoryStripeURL,
    isGettingInvoiceHistoryStripeURL,
  ] = useMutation<Services_createStripePortalSession_Mutation>(
    CREATE_STRIPE_PORTAL_SESSION_MUTATION
  );

  const [
    submitServiceRequests,
    isSubmittingServiceRequests,
  ] = useMutation<Services_submitServiceRequests_Mutation>(
    SUBMIT_SERVICE_REQUESTS_MUTATION
  );

  const [
    cancelServiceRequests,
    isCancellingServiceRequests,
  ] = useMutation<Services_cancelServiceRequests_Mutation>(
    CANCEL_SERVICE_REQUEST_MUTATION
  );

  const stripePortalRequestGraphQLConfig: UseMutationConfig<Services_createStripePortalSession_Mutation> = {
    variables: {},
    onCompleted(data, errors) {
      if (errors) {
        const errorFeedback = errors.map((error) => error.message).join(". \n");
        return setErrorFeedback(errorFeedback);
      }
      const stripeURL = data.createStripePortalSession?.stripePortalUrl ?? "";
      window.location.assign(stripeURL);
    },
    onError(error) {
      setErrorFeedback(
        <FormattedMessage
          id="pages.Services.stripePortalErrorFeedback"
          defaultMessage="Could not redirect to the configuration portal, please try again in a few minutes."
        />
      );
    },
  };

  const handleDeactivateService = () => {
    if (
      isSubmittingServiceRequests ||
      serviceActivationIdToDeactivate == null
    ) {
      return;
    }
    const serviceActivation = serviceActivationsData.serviceActivations?.find(
      (activation) => activation.id === serviceActivationIdToDeactivate
    );
    if (!serviceActivation) {
      return;
    }
    submitServiceRequests({
      variables: {
        input: {
          serviceRequests: [
            {
              applianceId: serviceActivation.appliance.id,
              serviceId: serviceActivation.service.id,
              type: serviceActivation.price
                ? "DEACTIVATE_BILLED_SERVICE"
                : "DEACTIVATE_SERVICE",
              priceId: serviceActivation.price?.id,
            },
          ],
        },
      },
      onCompleted(data, errors) {
        setServiceActivationIdToDeactivate(null);
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const serviceRequests =
          data.submitServiceRequests?.serviceRequests || [];
        if (serviceRequests.some((request) => request.status === "FULFILLED")) {
          onServiceActivationsUpdated();
        }
      },
      updater(store, data) {
        if (!data.submitServiceRequests) {
          return;
        }
        const root = store.getRoot();
        const oldRequestRecords =
          root.getLinkedRecords("serviceRequests") || [];
        const newRequestRecords = store
          .getRootField("submitServiceRequests")
          .getLinkedRecords("serviceRequests");
        root.setLinkedRecords(
          [...newRequestRecords, ...oldRequestRecords],
          "serviceRequests"
        );
        root
          .getLinkedRecords("serviceActivations")
          ?.forEach((serviceActivation) =>
            serviceActivation.invalidateRecord()
          );
      },
    });
  };

  const handleCancelServiceRequest = () => {
    if (isCancellingServiceRequests || serviceRequestIdToCancel == null) {
      return;
    }
    cancelServiceRequests({
      variables: {
        input: { serviceRequestIds: [serviceRequestIdToCancel] },
      },
      onCompleted(data, errors) {
        setServiceRequestIdToCancel(null);
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Services.cancelServiceRequestError"
            defaultMessage="Could not cancel the service request, please try again in a few minutes."
          />
        );
      },
    });
  };

  if (!serviceActivationsData.serviceActivations) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.services.noServiceActivations.message"
              defaultMessage="An error occurred while loading the active services."
            />
          }
        >
          <Link route={Route.profile}>
            <FormattedMessage
              id="pages.services.noServiceActivations.profileLink"
              defaultMessage="Return to the profile page"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <EmptyServiceSidebar />
        </SidebarContent>
      </>
    );
  }

  if (!serviceRequestsData.serviceRequests) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.services.noServiceRequests.message"
              defaultMessage="An error occurred while loading the service requests."
            />
          }
        >
          <Link route={Route.profile}>
            <FormattedMessage
              id="pages.services.noServiceRequests.profileLink"
              defaultMessage="Return to the profile page"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <EmptyServiceSidebar />
        </SidebarContent>
      </>
    );
  }

  const serviceRequestToCancel =
    serviceRequestIdToCancel &&
    serviceRequestsData.serviceRequests.find(
      (serviceRequest) => serviceRequest.id === serviceRequestIdToCancel
    );

  const serviceActivationToDeactivate =
    serviceActivationIdToDeactivate &&
    serviceActivationsData.serviceActivations.find(
      (serviceActivation) =>
        serviceActivation.id === serviceActivationIdToDeactivate
    );

  const hasBilledServices = servicesData.services
    ? servicesData.services.some((service) => service.prices.length > 0)
    : false;

  return (
    <Stack gap={4}>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      {hasBilledServices && canManageServicesBilling && (
        <Row xs={1} xl={3} className="g-4">
          <Col>
            <BillingSummaryCard
              serviceActivationsRef={serviceActivationsData.serviceActivations}
            />
          </Col>
          <Col>
            <SectionCard
              title={
                <FormattedMessage
                  id="pages.Services.paymentsCard.title"
                  defaultMessage="Manage Payments"
                />
              }
            >
              <p>
                <FormattedMessage
                  id="pages.Services.paymentsCard.description"
                  defaultMessage="Payment Method"
                />
              </p>
              <div className="d-flex flex-column flex-md-row">
                <Button
                  loading={isGettingPaymentMethodsStripeURL}
                  onClick={() =>
                    getPaymentMethodsStripeURL(stripePortalRequestGraphQLConfig)
                  }
                >
                  <FormattedMessage
                    id="pages.Services.paymentsCard.ManagePaymentMethodButton"
                    defaultMessage="Manage Payment Method"
                  />
                </Button>
              </div>
            </SectionCard>
          </Col>
          <Col>
            <SectionCard
              title={
                <FormattedMessage
                  id="pages.Services.paymentsHistoryCard.title"
                  defaultMessage="History"
                />
              }
            >
              <p>
                <FormattedMessage
                  id="pages.Services.paymentsHistoryCard.description"
                  defaultMessage="Invoice History"
                />
              </p>
              <div className="d-flex flex-column flex-md-row">
                <Button
                  loading={isGettingInvoiceHistoryStripeURL}
                  onClick={() =>
                    getInvoiceHistoryStripeURL(stripePortalRequestGraphQLConfig)
                  }
                >
                  <FormattedMessage
                    id="pages.Services.paymentsHistoryCard.ViewInvoiceHistoryButton"
                    defaultMessage="View Invoice History"
                  />
                </Button>
              </div>
            </SectionCard>
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12}>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.Services.serviceActivationsTable.title"
                defaultMessage="Manage Active Services"
              />
            }
            collapsible
          >
            <ActiveServicesTable
              serviceActivationsRef={serviceActivationsData.serviceActivations}
              onDeactivate={setServiceActivationIdToDeactivate}
            />
          </SectionCard>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SectionCard
            title={
              <FormattedMessage
                id="pages.Services.servicesTable.title"
                defaultMessage="Manage Requested Changes"
              />
            }
            collapsible
          >
            <ServiceRequestsTable
              serviceRequestsRef={serviceRequestsData.serviceRequests}
              onCancel={setServiceRequestIdToCancel}
            />
          </SectionCard>
        </Col>
      </Row>
      {serviceActivationToDeactivate && (
        <DeleteModal
          confirmText={serviceActivationToDeactivate.service.name}
          onCancel={() => setServiceActivationIdToDeactivate(null)}
          onConfirm={handleDeactivateService}
          isDeleting={isSubmittingServiceRequests}
          title={
            <FormattedMessage
              id="pages.Services.deactivateServiceModal.title"
              defaultMessage="Deactivate Service"
              description="Title for the confirmation modal to deactivate a service"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Services.deactivateServiceModal.description"
              defaultMessage="This action will deactivate the service <bold>{service}</bold> on the appliance <bold>{appliance}</bold>."
              description="Description for the confirmation modal to deactivate a service"
              values={{
                appliance: serviceActivationToDeactivate.appliance.name,
                service: serviceActivationToDeactivate.service.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
      {serviceRequestToCancel && (
        <DeleteModal
          confirmText={serviceRequestToCancel.service.name}
          onCancel={() => setServiceRequestIdToCancel(null)}
          onConfirm={handleCancelServiceRequest}
          isDeleting={isCancellingServiceRequests}
          title={
            <FormattedMessage
              id="pages.Services.cancelServiceRequestModal.title"
              defaultMessage="Cancel Service Request"
              description="Title for the confirmation modal to cancel a service request"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Services.cancelServiceRequestModal.description"
              defaultMessage="This action cannot be undone. This will permanently cancel the request for the service <bold>{service}</bold> on the appliance <bold>{appliance}</bold>."
              description="Description for the confirmation modal to cancel a service request"
              values={{
                appliance: serviceRequestToCancel.appliance.name,
                service: serviceRequestToCancel.service.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </Stack>
  );
};

const Services = () => {
  const [
    getServiceActivationsQuery,
    getServiceActivations,
  ] = useQueryLoader<Services_GetServiceActivations_Query>(
    GET_SERVICE_ACTIVATIONS_QUERY
  );
  const [
    getServiceRequestsQuery,
    getServiceRequests,
  ] = useQueryLoader<Services_GetServiceRequests_Query>(
    GET_SERVICE_REQUESTS_QUERY
  );
  const [
    getServicesQuery,
    getServices,
  ] = useQueryLoader<Services_GetServices_Query>(GET_SERVICES_QUERY);
  const [getViewerQuery, getViewer] = useQueryLoader<Services_getViewer_Query>(
    GET_VIEWER_QUERY
  );

  const getData = useCallback(() => {
    getServiceActivations({});
    getServiceRequests({});
    getServices({});
    getViewer({});
  }, [getServiceActivations, getServiceRequests, getServices, getViewer]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Services.title"
          defaultMessage="Services"
          description="Title for the Services page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <EmptyServiceSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <EmptyServiceSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getData();
          }}
        >
          {getServiceActivationsQuery &&
            getServiceRequestsQuery &&
            getServicesQuery && (
              <ServicesContent
                getServiceActivationsQuery={getServiceActivationsQuery}
                getServiceRequestsQuery={getServiceRequestsQuery}
                getServicesQuery={getServicesQuery}
                onServiceActivationsUpdated={() => getServiceActivations({})}
              />
            )}
          {getViewerQuery && (
            <SidebarContent>
              <ServicesSidebar getViewerQuery={getViewerQuery} />
            </SidebarContent>
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Services;
