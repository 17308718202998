import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import type { Role_deleteRole_Mutation } from "api/__generated__/Role_deleteRole_Mutation.graphql";
import type { Role_updateRole_Mutation } from "api/__generated__/Role_updateRole_Mutation.graphql";
import type {
  Permission,
  RoleInput,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import type { Role_getAllowedPermissions_Query } from "api/__generated__/Role_getAllowedPermissions_Query.graphql";
import type { Role_getRole_Query } from "api/__generated__/Role_getRole_Query.graphql";
import type { Role_getServices_Query } from "api/__generated__/Role_getServices_Query.graphql";
import * as images from "assets/images";
import { Link, Route, useNavigate } from "Navigation";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import Result from "components/Result";
import RoleForm from "components/RoleForm";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";
import { useViewer } from "contexts/Viewer";

const GET_ALLOWED_PERMISSIONS_QUERY = graphql`
  query Role_getAllowedPermissions_Query {
    viewer {
      organization {
        permissions
      }
    }
  }
`;

const GET_ROLE_QUERY = graphql`
  query Role_getRole_Query($id: ID!) {
    role(id: $id) {
      id
      name
      permissions
    }
  }
`;

const GET_SERVICES_QUERY = graphql`
  query Role_getServices_Query {
    services {
      __typename
      prices {
        __typename
      }
    }
  }
`;

const UPDATE_ROLE_MUTATION = graphql`
  mutation Role_updateRole_Mutation($input: UpdateRoleInput!) {
    updateRole(input: $input) {
      role {
        id
        name
        permissions
      }
    }
  }
`;

const DELETE_ROLE_MUTATION = graphql`
  mutation Role_deleteRole_Mutation($input: DeleteRoleInput!) {
    deleteRole(input: $input) {
      role {
        id
      }
    }
  }
`;

type RoleSidebarProps = {
  role?: Role_getRole_Query["response"]["role"];
  onDelete?: () => void;
};

const RoleSidebar = ({ role, onDelete }: RoleSidebarProps) => {
  const canDeleteRoles = useCanOneOf(["CAN_DELETE_ROLES"]);
  const canDeleteRole =
    canDeleteRoles && role != null && !["Admin", "Default"].includes(role.name);

  return (
    <Stack gap={3} className="mt-3 p-3">
      {role && <h4 className="text-center">{role.name}</h4>}
      <Image src={images.users} />
      {canDeleteRole && (
        <>
          <hr />
          <Center>
            <Button variant="solid-danger" onClick={onDelete}>
              <FormattedMessage
                id="pages.Role.deleteRoleButton"
                defaultMessage="Delete Role"
              />
            </Button>
          </Center>
        </>
      )}
    </Stack>
  );
};

const roleToRoleInput = (
  role: {
    readonly id: string;
    readonly name: string;
    readonly permissions: readonly Permission[];
  } | null
): RoleInput => ({
  name: role?.name || "",
  permissions: role?.permissions.slice() || [],
});

interface RoleContentProps {
  getAllowedPermissionsQuery: PreloadedQuery<Role_getAllowedPermissions_Query>;
  getRoleQuery: PreloadedQuery<Role_getRole_Query>;
  getServicesQuery: PreloadedQuery<Role_getServices_Query>;
}

const RoleContent = ({
  getAllowedPermissionsQuery,
  getRoleQuery,
  getServicesQuery,
}: RoleContentProps) => {
  const { roleId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const viewer = useViewer();
  const canUpdateRoles = useCanOneOf(["CAN_EDIT_ROLES"]);
  const data = usePreloadedQuery(GET_ROLE_QUERY, getRoleQuery);
  const servicesData = usePreloadedQuery(GET_SERVICES_QUERY, getServicesQuery);
  const allowedPermissionsData = usePreloadedQuery(
    GET_ALLOWED_PERMISSIONS_QUERY,
    getAllowedPermissionsQuery
  );

  // TODO: handle readonly type without mapping to mutable type
  const role = useMemo(
    () =>
      data.role && {
        ...data.role,
        permissions: [...data.role.permissions],
      },
    [data.role]
  );

  const [draft, setDraft] = useState<{ role: RoleInput; isValid: boolean }>({
    role: roleToRoleInput(role),
    isValid: true,
  });

  const services = useMemo(() => servicesData.services || [], [servicesData]);
  const hasServices = services.length > 0;
  const hasBilledServices = services.some(
    (service) => service.prices.length > 0
  );

  const allowedPermissions = useMemo(
    () =>
      allowedPermissionsData.viewer
        ? allowedPermissionsData.viewer.organization.permissions.slice()
        : [],
    [allowedPermissionsData.viewer]
  );

  const visiblePermissions = useMemo(() => {
    let permissions =
      role?.name === "Admin"
        ? // Admin role might have unapplied permissions that we don't want to show.
          // This is because Admin has all permissions by default, but some are not
          // enabled for the organization (e.g. Appliance Model management) and the
          // organization shouldn't know about non applicable permissions.
          allowedPermissions
        : // Other roles can have unapplied permissions only if the organization
          // previously had the capability to manage them but they are now forbidden.
          // In this case it's preferable to display the existing permissions even if
          // they are not currently applicable.
          _.union(allowedPermissions, role?.permissions || []);
    if (!hasBilledServices) {
      // If no paid services exist, permissions related to billing can be hidden
      permissions = _.difference(permissions, ["CAN_MANAGE_SERVICES_BILLING"]);
    }
    if (!hasServices) {
      // If no services exist, permissions related to services can be hidden
      permissions = _.difference(permissions, [
        "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
      ]);
    }
    return permissions;
  }, [allowedPermissions, role, hasServices, hasBilledServices]);

  const [updateRole, isUpdatingRole] = useMutation<Role_updateRole_Mutation>(
    UPDATE_ROLE_MUTATION
  );

  const [deleteRole, isDeletingRole] = useMutation<Role_deleteRole_Mutation>(
    DELETE_ROLE_MUTATION
  );

  const handleDeleteRole = useCallback(() => {
    deleteRole({
      variables: { input: { roleId } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          setShowDeleteModal(false);
          return setErrorFeedback(errorFeedback);
        }
        viewer.refresh();
        navigate({ route: Route.roles });
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Role.saveErrorFeedback"
            defaultMessage="Could not delete the role, please try again."
            description="Feedback for unknown deletion error in the Role page"
          />
        );
        setShowDeleteModal(false);
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [deleteRole, navigate, roleId, viewer]);

  const handleUpdateRole = useCallback(() => {
    updateRole({
      variables: { input: { roleId, ...draft.role } },
      onCompleted(data, errors) {
        if (errors) {
          const updateErrorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(updateErrorFeedback);
        }
        viewer.refresh();
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.Role.updateRoleErrorFeedback"
            defaultMessage="Could not update the role, please try again."
            description="Feedback for unknown error while updating a role"
          />
        );
      },
    });
  }, [updateRole, roleId, draft.role, viewer]);

  const handleResetDraft = useCallback(() => {
    setDraft({
      role: roleToRoleInput(role),
      isValid: true,
    });
  }, [role]);

  useEffect(() => {
    setDraft({
      role: roleToRoleInput(role),
      isValid: true,
    });
  }, [role]);

  if (!role) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.role.roleNotFound.title"
              defaultMessage="Role not found."
              description="Page title for a role not found"
            />
          }
        >
          <Link route={Route.roles}>
            <FormattedMessage
              id="pages.role.roleNotFound.message"
              defaultMessage="Return to the role list"
              description="Page message for a role not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <RoleSidebar />
        </SidebarContent>
      </>
    );
  }

  const canUpdateRoleName =
    canUpdateRoles && !["Admin", "Default"].includes(role.name);
  const canUpdateRolePermissions = canUpdateRoles && "Admin" !== role.name;
  const canUpdateRole = canUpdateRoleName || canUpdateRolePermissions;
  const isDirtyDraft = !_.isEqual(draft.role, roleToRoleInput(role));
  const canSubmitDraft = isDirtyDraft && draft.isValid && !isUpdatingRole;

  return (
    <Stack gap={3}>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <RoleForm
        readOnlyName={!canUpdateRoleName}
        readOnlyPermissions={!canUpdateRolePermissions}
        value={draft.role}
        onChange={(role, isValid) => setDraft({ role, isValid })}
        allowedPermissions={allowedPermissions}
        visiblePermissions={visiblePermissions}
      />
      {canUpdateRole && (
        <div className="mt-3 d-flex justify-content-end flex-column flex-md-row gap-2">
          <Button
            className="order-md-last"
            disabled={!canSubmitDraft}
            loading={isUpdatingRole}
            onClick={handleUpdateRole}
          >
            <FormattedMessage
              id="pages.Role.updateRoleButton"
              defaultMessage="Update Role"
            />
          </Button>
          <Button
            variant="outline-primary"
            disabled={!isDirtyDraft}
            onClick={handleResetDraft}
          >
            <FormattedMessage
              id="pages.Role.resetFormButton"
              defaultMessage="Reset"
            />
          </Button>
        </div>
      )}
      <SidebarContent>
        <RoleSidebar role={role} onDelete={() => setShowDeleteModal(true)} />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={role.name}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteRole}
          isDeleting={isDeletingRole}
          title={
            <FormattedMessage
              id="pages.Role.deleteModal.title"
              defaultMessage="Delete Role"
              description="Title for the confirmation modal to delete a role"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Role.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the role <bold>{role}</bold>."
              description="Description for the confirmation modal to delete a role"
              values={{
                role: role.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </Stack>
  );
};

const Role = () => {
  const { roleId = "" } = useParams();

  const [getRoleQuery, getRole] = useQueryLoader<Role_getRole_Query>(
    GET_ROLE_QUERY
  );

  const [
    getServicesQuery,
    getServices,
  ] = useQueryLoader<Role_getServices_Query>(GET_SERVICES_QUERY);

  const [
    getAllowedPermissionsQuery,
    getAllowedPermissions,
  ] = useQueryLoader<Role_getAllowedPermissions_Query>(
    GET_ALLOWED_PERMISSIONS_QUERY
  );

  const getData = useCallback(() => {
    getAllowedPermissions({});
    getRole({ id: roleId });
    getServices({});
  }, [getAllowedPermissions, getRole, getServices, roleId]);

  useEffect(getData, [getData]);

  return (
    <Page
      title={
        <FormattedMessage id="pages.Role.title" defaultMessage="Role Details" />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <RoleSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <RoleSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getAllowedPermissionsQuery && getRoleQuery && getServicesQuery && (
            <RoleContent
              getAllowedPermissionsQuery={getAllowedPermissionsQuery}
              getRoleQuery={getRoleQuery}
              getServicesQuery={getServicesQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Role;
