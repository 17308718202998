/**
 * @generated SignedSource<<517bd0a9add150ea50d0339d4c841a8f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Services_GetServiceRequests_Query$variables = {};
export type Services_GetServiceRequests_Query$data = {
  readonly serviceRequests: ReadonlyArray<{
    readonly appliance: {
      readonly name: string;
    };
    readonly id: string;
    readonly service: {
      readonly name: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"ServiceRequestsTable_ServiceRequestsStatusFragment">;
  }> | null;
};
export type Services_GetServiceRequests_Query = {
  response: Services_GetServiceRequests_Query$data;
  variables: Services_GetServiceRequests_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_GetServiceRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceRequest",
        "kind": "LinkedField",
        "name": "serviceRequests",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appliance",
            "kind": "LinkedField",
            "name": "appliance",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ServiceRequestsTable_ServiceRequestsStatusFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Services_GetServiceRequests_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceRequest",
        "kind": "LinkedField",
        "name": "serviceRequests",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Service",
            "kind": "LinkedField",
            "name": "service",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Appliance",
            "kind": "LinkedField",
            "name": "appliance",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "serial",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "tags",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "requestedAt",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "paymentUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "price",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "amount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "currency",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "interval",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "intervalCount",
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d8871e3f0e74836338b5f47d42a44076",
    "id": null,
    "metadata": {},
    "name": "Services_GetServiceRequests_Query",
    "operationKind": "query",
    "text": "query Services_GetServiceRequests_Query {\n  serviceRequests {\n    id\n    service {\n      name\n      id\n    }\n    appliance {\n      name\n      id\n    }\n    ...ServiceRequestsTable_ServiceRequestsStatusFragment\n  }\n}\n\nfragment ServiceRequestsTable_ServiceRequestsStatusFragment on ServiceRequest {\n  id\n  type\n  status\n  requestedAt\n  paymentUrl\n  service {\n    name\n    id\n  }\n  price {\n    amount\n    currency\n    interval\n    intervalCount\n    id\n  }\n  appliance {\n    name\n    serial\n    tags\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "de23df70fa756ab39db09d26b0936197";

export default node;
