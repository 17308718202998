/**
 * @generated SignedSource<<7d6891792f9d2d249aaafcb3771f5aa5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UnavailableApplianceApp_ServicesFragment$data = ReadonlyArray<{
  readonly description: string | null;
  readonly id: string;
  readonly name: string;
  readonly prices: ReadonlyArray<{
    readonly amount: number;
    readonly currency: string;
    readonly id: string;
    readonly interval: string | null;
    readonly intervalCount: number | null;
  }>;
  readonly " $fragmentType": "UnavailableApplianceApp_ServicesFragment";
}>;
export type UnavailableApplianceApp_ServicesFragment$key = ReadonlyArray<{
  readonly " $data"?: UnavailableApplianceApp_ServicesFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UnavailableApplianceApp_ServicesFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UnavailableApplianceApp_ServicesFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "prices",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "amount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "currency",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "interval",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "intervalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Service",
  "abstractKey": null
};
})();

(node as any).hash = "1e273fe105dc136799b5139ca1dbb6d2";

export default node;
