import { Suspense, useEffect, useMemo } from "react";
import graphql from "babel-plugin-relay/macro";
import {
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router-dom";

import { useGlobalApps } from "contexts/GlobalApps";
import type { GlobalApp } from "contexts/GlobalApps";

import type { Application_application_Query } from "api/__generated__/Application_application_Query.graphql";
import Result from "components/Result";
import ErrorBoundary from "components/ErrorBoundary";
import GlobalAppContainer from "components/GlobalApp";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_APPLICATION_APPLIANCES = graphql`
  query Application_application_Query($input: ID!) {
    application(id: $input) {
      id
      supportedAppliances {
        name
        deviceId
        tags
      }
    }
  }
`;

type ApplicationSidebarProps = {
  availableApplication: GlobalApp;
};

const ApplicationSidebar = ({
  availableApplication,
}: ApplicationSidebarProps) => {
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <h4>{availableApplication.application.displayName}</h4>
    </Stack>
  );
};

type ApplicationContentProps = {
  availableApplication: GlobalApp;
  getApplicationAppliancesQuery: PreloadedQuery<Application_application_Query>;
};
const ApplicationContent = ({
  availableApplication,
  getApplicationAppliancesQuery,
}: ApplicationContentProps) => {
  const appUrl = useMemo(
    () => new URL(availableApplication.application.sourceUrl),
    [availableApplication.application.sourceUrl]
  );
  const applicationAppliancesData = usePreloadedQuery(
    GET_APPLICATION_APPLIANCES,
    getApplicationAppliancesQuery
  );
  const appAppliances =
    applicationAppliancesData?.application?.supportedAppliances || [];
  const appProps = useMemo(() => {
    const readWriteAppliances = appAppliances.map((appliance) => ({
      name: appliance.name,
      deviceId: appliance.deviceId,
      tags: [...appliance.tags],
    }));

    return {
      token: availableApplication.astarteCredentials.authToken,
      astarteUrl: new URL(availableApplication.astarteCredentials.baseApiUrl),
      realm: availableApplication.astarteCredentials.realmName,
      appliances: readWriteAppliances,
    };
  }, [
    appAppliances,
    availableApplication.astarteCredentials.authToken,
    availableApplication.astarteCredentials.baseApiUrl,
    availableApplication.astarteCredentials.realmName,
  ]);

  return (
    <GlobalAppContainer
      appId={availableApplication.application.id}
      appUrl={appUrl}
      appProps={appProps}
    />
  );
};

const Application = () => {
  const { applicationSlug = "" } = useParams();
  const { applications } = useGlobalApps();
  const availableApplication = useMemo<GlobalApp | null>(() => {
    return (
      (applications &&
        applications.find(
          ({ application }) => application.slug === applicationSlug
        )) ||
      null
    );
  }, [applications, applicationSlug]);
  const [
    getApplicationAppliancesQuery,
    getApplicationAppliances,
  ] = useQueryLoader<Application_application_Query>(GET_APPLICATION_APPLIANCES);
  const appId = availableApplication?.application.id || null;

  useEffect(() => {
    if (appId) {
      getApplicationAppliances({ input: appId });
    }
  }, [appId, getApplicationAppliances]);

  if (!availableApplication || !appId) {
    return (
      <Page>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.application.applicationNotFound.title"
              defaultMessage="Application not found."
              description="Page title for an application not found"
            />
          }
        ></Result.NotFound>
      </Page>
    );
  }

  return (
    <Page>
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ApplicationSidebar availableApplication={availableApplication} />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ApplicationSidebar
                  availableApplication={availableApplication}
                />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getApplicationAppliances({ input: appId });
          }}
        >
          {getApplicationAppliancesQuery && (
            <ApplicationContent
              availableApplication={availableApplication}
              getApplicationAppliancesQuery={getApplicationAppliancesQuery}
            />
          )}
          <SidebarContent>
            <ApplicationSidebar availableApplication={availableApplication} />
          </SidebarContent>
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Application;
