/**
 * @generated SignedSource<<d685d10003cdd61eab18959a1fff984c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Appliance_getAppliance_Query$variables = {
  id: string;
};
export type Appliance_getAppliance_Query$data = {
  readonly appliance: {
    readonly assignee: {
      readonly id: string;
      readonly name: string;
    } | null;
    readonly availableApplications: ReadonlyArray<{
      readonly application: {
        readonly displayName: string;
        readonly id: string;
        readonly protocol: string;
        readonly sourceUrl: string;
      };
      readonly astarteCredentials: {
        readonly authToken: string;
      };
    }>;
    readonly device: {
      readonly baseApiUrl: string;
      readonly deviceId: string;
      readonly online: boolean;
      readonly realm: string;
      readonly " $fragmentSpreads": FragmentRefs<"Appliance_provisioningStatus">;
    };
    readonly id: string;
    readonly model: {
      readonly pictureUrl: string | null;
      readonly " $fragmentSpreads": FragmentRefs<"ApplianceModelDetails">;
    } | null;
    readonly name: string;
    readonly partNumber: string | null;
    readonly serial: string;
    readonly tags: ReadonlyArray<string>;
    readonly unavailableApplications: ReadonlyArray<{
      readonly displayName: string;
      readonly id: string;
      readonly protocol: string;
      readonly requiresAnyOfServices: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"UnavailableApplianceApp_ServicesFragment">;
      }>;
    }>;
    readonly " $fragmentSpreads": FragmentRefs<"ApplianceOperations_applianceId" | "ClientAssignee_applianceAssignee">;
  } | null;
};
export type Appliance_getAppliance_Query = {
  response: Appliance_getAppliance_Query$data;
  variables: Appliance_getAppliance_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serial",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partNumber",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tags",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deviceId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "realm",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "baseApiUrl",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "online",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "protocol",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "displayName",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "AvailableApplication",
  "kind": "LinkedField",
  "name": "availableApplications",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Application",
      "kind": "LinkedField",
      "name": "application",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        (v11/*: any*/),
        (v12/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sourceUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AstarteCredentials",
      "kind": "LinkedField",
      "name": "astarteCredentials",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "authToken",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pictureUrl",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "assignee",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    (v3/*: any*/)
  ],
  "storageKey": null
},
v16 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "locale",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "text",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Appliance_getAppliance_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "Appliance_provisioningStatus"
              }
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "unavailableApplications",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v12/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "requiresAnyOfServices",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UnavailableApplianceApp_ServicesFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplianceModel",
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ApplianceModelDetails"
              }
            ],
            "storageKey": null
          },
          (v15/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ApplianceOperations_applianceId"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ClientAssignee_applianceAssignee"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Appliance_getAppliance_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Appliance",
        "kind": "LinkedField",
        "name": "appliance",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Device",
            "kind": "LinkedField",
            "name": "device",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ProvisioningStatus",
                "kind": "LinkedField",
                "name": "provisioningStatus",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "osCodeId",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "osVersion",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v13/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Application",
            "kind": "LinkedField",
            "name": "unavailableApplications",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v12/*: any*/),
              (v11/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Service",
                "kind": "LinkedField",
                "name": "requiresAnyOfServices",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Price",
                    "kind": "LinkedField",
                    "name": "prices",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "amount",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "currency",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "interval",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "intervalCount",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ApplianceModel",
            "kind": "LinkedField",
            "name": "model",
            "plural": false,
            "selections": [
              (v14/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "handle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocalizedText",
                "kind": "LinkedField",
                "name": "names",
                "plural": true,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "LocalizedText",
                "kind": "LinkedField",
                "name": "descriptions",
                "plural": true,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "partNumbers",
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v15/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c75fafc015fa9282596552180d2293a0",
    "id": null,
    "metadata": {},
    "name": "Appliance_getAppliance_Query",
    "operationKind": "query",
    "text": "query Appliance_getAppliance_Query(\n  $id: ID!\n) {\n  appliance(id: $id) {\n    id\n    name\n    serial\n    partNumber\n    tags\n    device {\n      deviceId\n      realm\n      baseApiUrl\n      online\n      ...Appliance_provisioningStatus\n      id\n    }\n    availableApplications {\n      application {\n        id\n        protocol\n        displayName\n        sourceUrl\n      }\n      astarteCredentials {\n        authToken\n      }\n    }\n    unavailableApplications {\n      id\n      displayName\n      protocol\n      requiresAnyOfServices {\n        ...UnavailableApplianceApp_ServicesFragment\n        id\n      }\n    }\n    model {\n      pictureUrl\n      ...ApplianceModelDetails\n      id\n    }\n    assignee {\n      id\n      name\n    }\n    ...ApplianceOperations_applianceId\n    ...ClientAssignee_applianceAssignee\n  }\n}\n\nfragment ApplianceModelDetails on ApplianceModel {\n  handle\n  names {\n    locale\n    text\n  }\n  descriptions {\n    locale\n    text\n  }\n  partNumbers\n}\n\nfragment ApplianceOperations_applianceId on Appliance {\n  id\n}\n\nfragment Appliance_provisioningStatus on Device {\n  provisioningStatus {\n    osCodeId\n    osVersion\n  }\n}\n\nfragment ClientAssignee_applianceAssignee on Appliance {\n  id\n  assignee {\n    id\n    name\n  }\n}\n\nfragment UnavailableApplianceApp_ServicesFragment on Service {\n  id\n  name\n  description\n  prices {\n    id\n    amount\n    currency\n    interval\n    intervalCount\n  }\n}\n"
  }
};
})();

(node as any).hash = "36c99da07b5cd73505ae94dbfb6b0b8f";

export default node;
