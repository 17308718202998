import graphql from "babel-plugin-relay/macro";
import { FormattedDate, FormattedMessage } from "react-intl";
import { useFragment } from "react-relay/hooks";

import {
  ActiveServicesTable_ServiceActivationsStatusFragment$data,
  ActiveServicesTable_ServiceActivationsStatusFragment$key,
} from "api/__generated__/ActiveServicesTable_ServiceActivationsStatusFragment.graphql";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Stack from "components/Stack";
import StyledTable from "components/StyledTable";
import type { Column } from "components/StyledTable";
import Tag from "components/Tag";
import { useTenantConfig } from "contexts/TenantConfig";

const SERVICE_ACTIVATIONS_STATUS_FRAGMENT = graphql`
  fragment ActiveServicesTable_ServiceActivationsStatusFragment on ServiceActivation
  @relay(plural: true) {
    id
    activatedAt
    owned
    service {
      name
    }
    price {
      __typename
    }
    appliance {
      name
      serial
      tags
    }
  }
`;

type Row = ActiveServicesTable_ServiceActivationsStatusFragment$data[0];

type DeactivationHandler = (id: string) => void;

type DeactivateServiceButtonProps = {
  onDeactivate: DeactivationHandler;
  serviceActivation: Row;
};

const DeactivateServiceButton = ({
  onDeactivate,
  serviceActivation,
}: DeactivateServiceButtonProps) => {
  const { paymentsEnabled } = useTenantConfig();
  const canManageServicesBilling =
    useCanOneOf(["CAN_MANAGE_SERVICES_BILLING"]) && paymentsEnabled;
  const canManageServicesWithoutPayment = useCanOneOf([
    "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
  ]);

  if (!serviceActivation.price && !canManageServicesWithoutPayment) {
    return null;
  }
  if (serviceActivation.price && !canManageServicesBilling) {
    return null;
  }
  if (!serviceActivation.owned) {
    return null;
  }
  return (
    <Button
      variant="outline-danger"
      onClick={() => onDeactivate(serviceActivation.id)}
    >
      <FormattedMessage
        id="components.ActiveServicesTable.deactivateButton"
        defaultMessage="Deactivate"
      />
    </Button>
  );
};

const useColumns: (onDeactivate: DeactivationHandler) => Column<Row>[] = (
  onDeactivate
) => {
  return [
    {
      id: "applianceName",
      accessorFn: (row) => row.appliance.name,
      header: () => (
        <FormattedMessage
          id="components.ActiveServicesTable.applianceNameTitle"
          defaultMessage="Appliance Name"
        />
      ),
      cell: ({ row }) => (
        <Stack gap={2} className="py-2 py-xl-0">
          <Stack gap={0}>
            <span className="text-nowrap">{row.original.appliance.name}</span>
            <span className="d-xl-none text-muted text-nowrap">
              <small>{row.original.appliance.serial}</small>
            </span>
          </Stack>
          <Stack gap={0} className="d-xl-none">
            <span className="text-nowrap">{row.original.service.name}</span>
            <span className="text-muted text-nowrap">
              <small>
                <FormattedMessage
                  id="components.ActiveServicesTable.activatedAtTitle"
                  defaultMessage="Activated at"
                />
                &nbsp;
                <FormattedDate
                  value={row.original.activatedAt}
                  year="numeric"
                  month="long"
                  day="numeric"
                />
              </small>
            </span>
          </Stack>
          <div className="d-xl-none d-flex flex-column flex-xl-row">
            <DeactivateServiceButton
              serviceActivation={row.original}
              onDeactivate={onDeactivate}
            />
          </div>
        </Stack>
      ),
    },
    {
      id: "applianceSerialNumber",
      accessorFn: (row) => row.appliance.serial,
      header: () => (
        <FormattedMessage
          id="components.ActiveServicesTable.serialTitle"
          defaultMessage="Appliance S/N"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "applianceTags",
      accessorFn: (row) => row.appliance.tags,
      enableSorting: false,
      header: () => (
        <FormattedMessage
          id="components.ActiveServicesTable.tagsTitle"
          defaultMessage="Tags"
        />
      ),
      cell: ({ getValue }) => {
        const value = getValue() as string[];
        return (
          <>
            {value.map((tag) => (
              <Tag key={tag} className="me-2">
                {tag}
              </Tag>
            ))}
          </>
        );
      },
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      id: "serviceName",
      accessorFn: (row) => row.service.name,
      header: () => (
        <FormattedMessage
          id="components.ActiveServicesTable.serviceNameTitle"
          defaultMessage="Service"
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "activatedAt",
      header: () => (
        <FormattedMessage
          id="components.ActiveServicesTable.activatedAtTitle"
          defaultMessage="Activated at"
        />
      ),
      cell: ({ getValue }) => {
        const date = getValue() as Date;
        return (
          <FormattedDate
            value={date}
            year="numeric"
            month="long"
            day="numeric"
          />
        );
      },
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
    {
      accessorKey: "id",
      header: () => null,
      enableSorting: false,
      enableGlobalFilter: false,
      cell: ({ row }) => (
        <DeactivateServiceButton
          serviceActivation={row.original}
          onDeactivate={onDeactivate}
        />
      ),
      meta: {
        className: "d-none d-xl-table-cell",
      },
    },
  ];
};

type Props = {
  className?: string;
  serviceActivationsRef: ActiveServicesTable_ServiceActivationsStatusFragment$key;
  searchText?: string;
  onDeactivate: DeactivationHandler;
};

const ActiveServicesTable = ({
  className,
  serviceActivationsRef,
  searchText,
  onDeactivate,
}: Props) => {
  const data = useFragment(
    SERVICE_ACTIVATIONS_STATUS_FRAGMENT,
    serviceActivationsRef
  ).filter((serviceActivation) => serviceActivation.owned);

  const columns = useColumns(onDeactivate);

  if (data.length === 0) {
    return (
      <FormattedMessage
        id="components.ActiveServicesTable.noServiceActivationsFeedback"
        defaultMessage="There are no active services."
      />
    );
  }

  return (
    <StyledTable
      className={className}
      columns={columns}
      data={data}
      searchText={searchText}
    />
  );
};

export default ActiveServicesTable;
