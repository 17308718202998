import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
} from "react-relay/hooks";
import Alert from "react-bootstrap/Alert";
import _ from "lodash";

import type {
  RoleCreate_createRole_Mutation,
  RoleInput,
} from "api/__generated__/RoleCreate_createRole_Mutation.graphql";
import type { RoleCreate_getAllowedPermissions_Query } from "api/__generated__/RoleCreate_getAllowedPermissions_Query.graphql";
import type { RoleCreate_getServices_Query } from "api/__generated__/RoleCreate_getServices_Query.graphql";
import * as images from "assets/images";
import { Route, useNavigate } from "Navigation";
import Button from "components/Button";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, { PageLoading, PageLoadingError } from "components/Page";
import RoleForm from "components/RoleForm";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_ALLOWED_PERMISSIONS_QUERY = graphql`
  query RoleCreate_getAllowedPermissions_Query {
    viewer {
      organization {
        permissions
      }
    }
  }
`;

const GET_SERVICES_QUERY = graphql`
  query RoleCreate_getServices_Query {
    services {
      __typename
      prices {
        __typename
      }
    }
  }
`;

const CREATE_ROLE_MUTATION = graphql`
  mutation RoleCreate_createRole_Mutation($input: CreateRoleInput!) {
    createRole(input: $input) {
      role {
        id
        name
        permissions
      }
    }
  }
`;

const RoleCreateSidebar = () => {
  return (
    <Stack gap={3} className="mt-3 p-3">
      <Image src={images.users} />
    </Stack>
  );
};

const initialDraft: RoleInput = {
  name: "",
  permissions: [],
};

interface RoleCreateContentProps {
  getAllowedPermissionsQuery: PreloadedQuery<RoleCreate_getAllowedPermissions_Query>;
  getServicesQuery: PreloadedQuery<RoleCreate_getServices_Query>;
}

const RoleCreateContent = ({
  getAllowedPermissionsQuery,
  getServicesQuery,
}: RoleCreateContentProps) => {
  const [draft, setDraft] = useState({
    role: initialDraft,
    isValid: false,
  });
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const navigate = useNavigate();
  const [
    createRole,
    isCreatingRole,
  ] = useMutation<RoleCreate_createRole_Mutation>(CREATE_ROLE_MUTATION);
  const allowedPermissionsData = usePreloadedQuery(
    GET_ALLOWED_PERMISSIONS_QUERY,
    getAllowedPermissionsQuery
  );
  const servicesData = usePreloadedQuery(GET_SERVICES_QUERY, getServicesQuery);

  const services = useMemo(() => servicesData.services || [], [servicesData]);
  const hasServices = services.length > 0;
  const hasBilledServices = services.some(
    (service) => service.prices.length > 0
  );

  const allowedPermissions = useMemo(
    () =>
      allowedPermissionsData.viewer
        ? allowedPermissionsData.viewer.organization.permissions.slice()
        : [],
    [allowedPermissionsData.viewer]
  );

  const visiblePermissions = useMemo(() => {
    let permissions = allowedPermissions;
    if (!hasBilledServices) {
      // If no paid services exist, permissions related to billing can be hidden
      permissions = _.difference(permissions, ["CAN_MANAGE_SERVICES_BILLING"]);
    }
    if (!hasServices) {
      // If no services exist, permissions related to services can be hidden
      permissions = _.difference(permissions, [
        "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
      ]);
    }
    return permissions;
  }, [allowedPermissions, hasServices, hasBilledServices]);

  const handleCreateRole = useCallback(() => {
    createRole({
      variables: { input: { role: draft.role } },
      onCompleted(data, errors) {
        if (errors) {
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        const roleId = data.createRole?.role.id;
        if (roleId) {
          navigate({ route: Route.rolesEdit, params: { roleId } });
        }
      },
      onError(error) {
        setErrorFeedback(
          <FormattedMessage
            id="pages.RoleCreate.saveErrorFeedback"
            defaultMessage="Could not create the role, please try again."
            description="Feedback for unknown creation error in the RoleCreate page"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [draft.role, createRole, navigate]);

  return (
    <Stack gap={3}>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <RoleForm
        value={draft.role}
        onChange={(role, isValid) => setDraft({ role, isValid })}
        allowedPermissions={allowedPermissions}
        visiblePermissions={visiblePermissions}
      />
      <div className="mt-3 d-flex justify-content-end flex-column flex-md-row">
        <Button
          disabled={!draft.isValid || isCreatingRole}
          loading={isCreatingRole}
          onClick={handleCreateRole}
        >
          <FormattedMessage
            id="pages.RoleCreate.createRoleButton"
            defaultMessage="Create Role"
          />
        </Button>
      </div>
      <SidebarContent>
        <RoleCreateSidebar />
      </SidebarContent>
    </Stack>
  );
};

const RoleCreate = () => {
  const [
    getAllowedPermissionsQuery,
    getAllowedPermissions,
  ] = useQueryLoader<RoleCreate_getAllowedPermissions_Query>(
    GET_ALLOWED_PERMISSIONS_QUERY
  );
  const [
    getServicesQuery,
    getServices,
  ] = useQueryLoader<RoleCreate_getServices_Query>(GET_SERVICES_QUERY);

  const getData = useCallback(() => {
    getAllowedPermissions({});
    getServices({});
  }, [getAllowedPermissions, getServices]);

  useEffect(getData, [getData]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.RoleCreate.title"
          defaultMessage="Create Role"
          description="Title for the RoleCreate page"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <RoleCreateSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <RoleCreateSidebar />
              </SidebarContent>
            </>
          )}
          onReset={getData}
        >
          {getAllowedPermissionsQuery && getServicesQuery && (
            <RoleCreateContent
              getAllowedPermissionsQuery={getAllowedPermissionsQuery}
              getServicesQuery={getServicesQuery}
            />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default RoleCreate;
