import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage } from "react-intl";
import graphql from "babel-plugin-relay/macro";
import {
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
  PreloadedQuery,
  usePaginationFragment,
} from "react-relay/hooks";
import _ from "lodash";

import * as images from "assets/images";
import type { Client_getOrganization_Query } from "api/__generated__/Client_getOrganization_Query.graphql";
import type { Client_AppliancesPaginationQuery } from "api/__generated__/Client_AppliancesPaginationQuery.graphql";
import type { Client_AppliancesFragment$key } from "api/__generated__/Client_AppliancesFragment.graphql";
import type { Client_removeClient_Mutation } from "api/__generated__/Client_removeClient_Mutation.graphql";
import { Link, Route, useNavigate } from "Navigation";
import AppliancesTable from "components/AppliancesTable";
import Button from "components/Button";
import Can from "components/Can";
import Center from "components/Center";
import DeleteModal from "components/DeleteModal";
import ErrorBoundary from "components/ErrorBoundary";
import Image from "components/Image";
import Page, {
  PageLoading,
  PageLoadingError,
  PageToolbar,
} from "components/Page";
import Result from "components/Result";
import SearchBox from "components/SearchBox";
import { SidebarContent } from "components/Sidebar";
import Stack from "components/Stack";

const GET_ORGANIZATION_QUERY = graphql`
  query Client_getOrganization_Query(
    $id: ID!
    $first: Int!
    $after: String
    $filter: ApplianceFilter
  ) {
    organization(id: $id) {
      id
      name
      ...Client_AppliancesFragment
    }
  }
`;

const APPLIANCES_TO_LOAD_FIRST = 40;
const APPLIANCES_TO_LOAD_NEXT = 10;

const GET_CLIENT_APPLIANCES_FRAGMENT = graphql`
  fragment Client_AppliancesFragment on Organization
  @refetchable(queryName: "Client_AppliancesPaginationQuery") {
    appliances(first: $first, after: $after, filter: $filter)
      @connection(key: "Client_appliances", filters: ["filter"]) {
      edges {
        node {
          __typename
        }
      }
      ...AppliancesTable_ApplianceEdgeFragment
    }
  }
`;

const REMOVE_CLIENT_MUTATION = graphql`
  mutation Client_removeClient_Mutation($input: RemoveClientInput!) {
    removeClient(input: $input) {
      client {
        id
      }
    }
  }
`;

type ClientSidebarProps = {
  client?: Client_getOrganization_Query["response"]["organization"];
  onDelete?: () => void;
};

const ClientSidebar = ({ client, onDelete }: ClientSidebarProps) => {
  if (!client) {
    return (
      <Stack gap={3} className="mt-3 p-3">
        <Image src={images.clients} />
      </Stack>
    );
  }
  return (
    <Stack gap={3} className="mt-3 p-3 text-center">
      <h4>{client.name}</h4>
      <Image fallbackSrc={images.clients} />
      <Can oneOf={["CAN_REMOVE_CLIENTS"]}>
        <hr />
        <Center>
          <Button variant="solid-danger" onClick={onDelete}>
            <FormattedMessage
              id="pages.Client.deleteClientButton"
              defaultMessage="Delete Client"
            />
          </Button>
        </Center>
      </Can>
    </Stack>
  );
};

interface ClientAppliancesTableProps {
  client: NonNullable<Client_getOrganization_Query["response"]["organization"]>;
  searchText: string | null;
}

const ClientAppliancesTable = ({
  client,
  searchText,
}: ClientAppliancesTableProps) => {
  const {
    data,
    loadNext,
    hasNext,
    isLoadingNext,
    refetch,
  } = usePaginationFragment<
    Client_AppliancesPaginationQuery,
    Client_AppliancesFragment$key
  >(GET_CLIENT_APPLIANCES_FRAGMENT, client);

  const debounceRefetch = useMemo(
    () =>
      _.debounce((searchText: string) => {
        if (searchText === "") {
          return refetch(
            { id: client.id, first: APPLIANCES_TO_LOAD_FIRST },
            { fetchPolicy: "network-only" }
          );
        }
        refetch(
          {
            id: client.id,
            first: APPLIANCES_TO_LOAD_FIRST,
            filter: { matching: searchText },
          },
          { fetchPolicy: "network-only" }
        );
      }, 500),
    [refetch, client.id]
  );

  useEffect(() => {
    if (searchText !== null) {
      debounceRefetch(searchText);
    }
  }, [debounceRefetch, searchText]);

  const loadNextAppliances = useCallback(() => {
    if (hasNext && !isLoadingNext) {
      loadNext(APPLIANCES_TO_LOAD_NEXT);
    }
  }, [hasNext, isLoadingNext, loadNext]);

  const appliancesRef = data?.appliances || null;

  if (!appliancesRef) {
    return null;
  }

  return (
    <AppliancesTable
      appliancesRef={appliancesRef}
      loading={isLoadingNext}
      onLoadMore={hasNext ? loadNextAppliances : undefined}
    />
  );
};
interface ClientContentProps {
  getOrganizationQuery: PreloadedQuery<Client_getOrganization_Query>;
}

const ClientContent = ({ getOrganizationQuery }: ClientContentProps) => {
  const { clientId = "" } = useParams();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [errorFeedback, setErrorFeedback] = useState<React.ReactNode>(null);
  const [searchText, setSearchText] = useState<string | null>(null);
  const navigate = useNavigate();
  const clientData = usePreloadedQuery(
    GET_ORGANIZATION_QUERY,
    getOrganizationQuery
  );

  const [
    removeClient,
    isRemovingClient,
  ] = useMutation<Client_removeClient_Mutation>(REMOVE_CLIENT_MUTATION);

  const handleDeleteClient = useCallback(() => {
    removeClient({
      variables: { input: { clientId } },
      onCompleted(data, errors) {
        if (errors) {
          setShowDeleteModal(false);
          const errorFeedback = errors
            .map((error) => error.message)
            .join(". \n");
          return setErrorFeedback(errorFeedback);
        }
        navigate({ route: Route.clients });
      },
      onError(error) {
        setShowDeleteModal(false);
        setErrorFeedback(
          <FormattedMessage
            id="pages.Client.form.deleteClientErrorFeedback"
            defaultMessage="Could not delete client, please try again."
            description="Feedback for unknown error while deleting a client"
          />
        );
      },
      updater(store) {
        // TODO: should use and update Connections instead of invalidating the entire store
        // see https://relay.dev/docs/guided-tour/list-data/updating-connections/
        store.invalidateStore();
      },
    });
  }, [clientId, removeClient, navigate]);

  const client = clientData.organization;

  if (!client) {
    return (
      <>
        <Result.NotFound
          title={
            <FormattedMessage
              id="pages.client.clientNotFound.title"
              defaultMessage="Client not found."
              description="Page title for a client not found"
            />
          }
        >
          <Link route={Route.clients}>
            <FormattedMessage
              id="pages.client.clientNotFound.message"
              defaultMessage="Return to the client list"
              description="Page message for a client not found"
            />
          </Link>
        </Result.NotFound>
        <SidebarContent>
          <ClientSidebar />
        </SidebarContent>
      </>
    );
  }

  return (
    <>
      <PageToolbar>
        <SearchBox value={searchText || ""} onChange={setSearchText} />
      </PageToolbar>
      <Alert
        show={!!errorFeedback}
        variant="danger"
        onClose={() => setErrorFeedback(null)}
        dismissible
      >
        {errorFeedback}
      </Alert>
      <Suspense fallback={<AppliancesTable loading={true} />}>
        <ClientAppliancesTable client={client} searchText={searchText} />
      </Suspense>
      <SidebarContent>
        <ClientSidebar
          client={client}
          onDelete={() => setShowDeleteModal(true)}
        />
      </SidebarContent>
      {showDeleteModal && (
        <DeleteModal
          confirmText={client?.name || "ok"}
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={handleDeleteClient}
          isDeleting={isRemovingClient}
          title={
            <FormattedMessage
              id="pages.Client.deleteModal.title"
              defaultMessage="Delete Client"
              description="Title for the confirmation modal to delete a client"
            />
          }
        >
          <p>
            <FormattedMessage
              id="pages.Client.deleteModal.description"
              defaultMessage="This action cannot be undone. This will permanently delete the client <bold>{client}</bold>."
              description="Description for the confirmation modal to delete a client"
              values={{
                client: client?.name,
                bold: (chunks: React.ReactNode) => <strong>{chunks}</strong>,
              }}
            />
          </p>
        </DeleteModal>
      )}
    </>
  );
};

const Client = () => {
  const { clientId = "" } = useParams();
  const [
    getOrganizationQuery,
    getOrganization,
  ] = useQueryLoader<Client_getOrganization_Query>(GET_ORGANIZATION_QUERY);

  useEffect(() => {
    getOrganization(
      { id: clientId, first: APPLIANCES_TO_LOAD_FIRST },
      { fetchPolicy: "network-only" }
    );
  }, [getOrganization, clientId]);

  return (
    <Page
      title={
        <FormattedMessage
          id="pages.Client.title"
          defaultMessage="Client Appliances"
        />
      }
    >
      <Suspense
        fallback={
          <>
            <PageLoading />
            <SidebarContent>
              <ClientSidebar />
            </SidebarContent>
          </>
        }
      >
        <ErrorBoundary
          FallbackComponent={(props) => (
            <>
              <PageLoadingError onRetry={props.resetErrorBoundary} />
              <SidebarContent>
                <ClientSidebar />
              </SidebarContent>
            </>
          )}
          onReset={() => {
            getOrganization(
              { id: clientId, first: APPLIANCES_TO_LOAD_FIRST },
              { fetchPolicy: "network-only" }
            );
          }}
        >
          {getOrganizationQuery && (
            <ClientContent getOrganizationQuery={getOrganizationQuery} />
          )}
        </ErrorBoundary>
      </Suspense>
    </Page>
  );
};

export default Client;
