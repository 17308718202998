/**
 * @generated SignedSource<<1c841215f357baf19e90cb18b78df0ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalApps_getApplications_Query$variables = {};
export type GlobalApps_getApplications_Query$data = {
  readonly availableApplications: ReadonlyArray<{
    readonly application: {
      readonly displayName: string;
      readonly id: string;
      readonly protocol: string;
      readonly slug: string;
      readonly sourceUrl: string;
    };
    readonly astarteCredentials: {
      readonly authToken: string;
      readonly baseApiUrl: string;
      readonly realmName: string;
    };
  }>;
};
export type GlobalApps_getApplications_Query = {
  response: GlobalApps_getApplications_Query$data;
  variables: GlobalApps_getApplications_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AvailableApplication",
    "kind": "LinkedField",
    "name": "availableApplications",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Application",
        "kind": "LinkedField",
        "name": "application",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "slug",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "displayName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "protocol",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sourceUrl",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AstarteCredentials",
        "kind": "LinkedField",
        "name": "astarteCredentials",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "authToken",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "baseApiUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "realmName",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalApps_getApplications_Query",
    "selections": (v0/*: any*/),
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GlobalApps_getApplications_Query",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0aeb1c997f76a1ee6c4f294eccc3c66f",
    "id": null,
    "metadata": {},
    "name": "GlobalApps_getApplications_Query",
    "operationKind": "query",
    "text": "query GlobalApps_getApplications_Query {\n  availableApplications {\n    application {\n      id\n      slug\n      displayName\n      protocol\n      sourceUrl\n    }\n    astarteCredentials {\n      authToken\n      baseApiUrl\n      realmName\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7dc709b74cd43cb6ede980ee7ca879ce";

export default node;
