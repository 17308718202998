/**
 * @generated SignedSource<<55737d2fba5f7fe7cad2caebfebae234>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Permission = "CAN_ADD_CLIENTS" | "CAN_ASSIGN_APPLIANCES" | "CAN_CREATE_APPLIANCES" | "CAN_CREATE_APPLIANCE_MODELS" | "CAN_CREATE_ROLES" | "CAN_DELETE_APPLIANCES" | "CAN_DELETE_APPLIANCE_MODELS" | "CAN_DELETE_ROLES" | "CAN_DELETE_USERS" | "CAN_EDIT_APPLIANCES" | "CAN_EDIT_APPLIANCE_MODELS" | "CAN_EDIT_ROLES" | "CAN_GET_APPLIANCES" | "CAN_GET_APPLIANCE_MODELS" | "CAN_GET_ROLES" | "CAN_GET_USERS" | "CAN_INVITE_USERS" | "CAN_LIST_APPLIANCES" | "CAN_LIST_APPLIANCE_MODELS" | "CAN_LIST_CLIENTS" | "CAN_LIST_ROLES" | "CAN_LIST_USERS" | "CAN_MANAGE_SERVICES_BILLING" | "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT" | "CAN_MANAGE_USER_ROLES" | "CAN_REMOVE_CLIENTS" | "%future added value";
export type Role_getAllowedPermissions_Query$variables = {};
export type Role_getAllowedPermissions_Query$data = {
  readonly viewer: {
    readonly organization: {
      readonly permissions: ReadonlyArray<Permission>;
    };
  } | null;
};
export type Role_getAllowedPermissions_Query = {
  response: Role_getAllowedPermissions_Query$data;
  variables: Role_getAllowedPermissions_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "permissions",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Role_getAllowedPermissions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Role_getAllowedPermissions_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Organization",
            "kind": "LinkedField",
            "name": "organization",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "54b50a528cd9c14d70d32a5a237206ad",
    "id": null,
    "metadata": {},
    "name": "Role_getAllowedPermissions_Query",
    "operationKind": "query",
    "text": "query Role_getAllowedPermissions_Query {\n  viewer {\n    organization {\n      permissions\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d8faea6b8c6b81bc6c4a629f6c9f7af4";

export default node;
