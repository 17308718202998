/**
 * @generated SignedSource<<237bea2e4ebb160cd9699ff180ae5cb9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type ServiceRequestStatus = "CANCELLED" | "EXPIRED" | "FULFILLED" | "REQUIRES_PAYMENT" | "%future added value";
export type ServiceRequestType = "ACTIVATE_BILLED_SERVICE" | "ACTIVATE_SERVICE" | "DEACTIVATE_BILLED_SERVICE" | "DEACTIVATE_SERVICE" | "%future added value";
export type SubmitServiceRequestsInput = {
  serviceRequests: ReadonlyArray<ServiceRequestInput>;
};
export type ServiceRequestInput = {
  applianceId: string;
  priceId?: string | null;
  serviceId: string;
  type: ServiceRequestType;
};
export type Services_submitServiceRequests_Mutation$variables = {
  input: SubmitServiceRequestsInput;
};
export type Services_submitServiceRequests_Mutation$data = {
  readonly submitServiceRequests: {
    readonly serviceRequests: ReadonlyArray<{
      readonly id: string;
      readonly status: ServiceRequestStatus;
    }>;
  } | null;
};
export type Services_submitServiceRequests_Mutation = {
  response: Services_submitServiceRequests_Mutation$data;
  variables: Services_submitServiceRequests_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "SubmitServiceRequestsPayload",
    "kind": "LinkedField",
    "name": "submitServiceRequests",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ServiceRequest",
        "kind": "LinkedField",
        "name": "serviceRequests",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_submitServiceRequests_Mutation",
    "selections": (v1/*: any*/),
    "type": "RootMutationType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Services_submitServiceRequests_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "1a43d4c0dfdb635257b359a2031cd016",
    "id": null,
    "metadata": {},
    "name": "Services_submitServiceRequests_Mutation",
    "operationKind": "mutation",
    "text": "mutation Services_submitServiceRequests_Mutation(\n  $input: SubmitServiceRequestsInput!\n) {\n  submitServiceRequests(input: $input) {\n    serviceRequests {\n      id\n      status\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6633a3ca1013688833aae118f1c16d59";

export default node;
