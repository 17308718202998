/**
 * @generated SignedSource<<993027658e42fce337f9eefc1ef0339f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Role_getServices_Query$variables = {};
export type Role_getServices_Query$data = {
  readonly services: ReadonlyArray<{
    readonly __typename: "Service";
    readonly prices: ReadonlyArray<{
      readonly __typename: "Price";
    }>;
  }> | null;
};
export type Role_getServices_Query = {
  response: Role_getServices_Query$data;
  variables: Role_getServices_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Role_getServices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Role_getServices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "095842457a31cdf487cee7e0009bd220",
    "id": null,
    "metadata": {},
    "name": "Role_getServices_Query",
    "operationKind": "query",
    "text": "query Role_getServices_Query {\n  services {\n    __typename\n    prices {\n      __typename\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "245eb526f61ff9191a6f1b2c7f65a894";

export default node;
