import { FormattedMessage } from "react-intl";
import { graphql, useFragment } from "react-relay";
import Card from "react-bootstrap/Card";

import * as images from "assets/images";
import type { UnavailableApplianceApp_ServicesFragment$key } from "api/__generated__/UnavailableApplianceApp_ServicesFragment.graphql";
import Button from "components/Button";
import { useCanOneOf } from "components/Can";
import Price from "components/Price";
import { useTenantConfig } from "contexts/TenantConfig";

import "./UnavailableApplianceApp.scss";

const SERVICES_FRAGMENT = graphql`
  fragment UnavailableApplianceApp_ServicesFragment on Service
  @relay(plural: true) {
    id
    name
    description
    prices {
      id
      amount
      currency
      interval
      intervalCount
    }
  }
`;

type ServiceRequest =
  | { type: "ACTIVATE_SERVICE"; serviceId: string }
  | { type: "ACTIVATE_BILLED_SERVICE"; serviceId: string; priceId: string };

type Props = {
  className?: string;
  isSubmittingRequest?: boolean;
  servicesRef: UnavailableApplianceApp_ServicesFragment$key;
  onServiceRequest: (serviceRequest: ServiceRequest) => void;
};

const UnavailableApplianceApp = ({
  className = "",
  isSubmittingRequest = false,
  servicesRef,
  onServiceRequest,
}: Props): React.ReactElement => {
  const tenantConfig = useTenantConfig();
  const brandImg = tenantConfig.design.logo || images.brand;
  const canActivateServicesWithPayment = useCanOneOf([
    "CAN_MANAGE_SERVICES_BILLING",
  ]);
  const canActivateServicesWithoutPayment = useCanOneOf([
    "CAN_MANAGE_SERVICES_WITHOUT_PAYMENT",
  ]);
  const services = useFragment(SERVICES_FRAGMENT, servicesRef);
  const service = services[0]; // We assume only one service at max. TODO: support multiple services
  const price = service.prices.length > 0 ? service.prices[0] : null; // We assume only one price at max. TODO: support multiple prices

  return (
    <div
      className={`${className} appliance-service-modal d-flex justify-content-center align-items-center`}
    >
      <div className="position-absolute h-100 w-100 bg-light rounded" />
      <Card className="m-3 p-3 text-center">
        <img
          alt="Logo"
          src={brandImg}
          className="brand-logo mx-auto mw-100 mb-3"
        />
        <h2 className="mb-3">
          <FormattedMessage
            id="UnavailableApplianceApp.MainDescription"
            defaultMessage="This feature is not available right now"
          />
        </h2>
        <p className="mb-4">
          <FormattedMessage
            id="UnavailableApplianceApp.modal.SubDescription"
            defaultMessage="This feature is only available with the {serviceName} service."
            values={{ serviceName: service.name }}
          />
          <br />
          {service.description}
        </p>
        {canActivateServicesWithoutPayment ? (
          <Button
            className="mx-auto px-5"
            variant="solid-primary"
            disabled={isSubmittingRequest}
            loading={isSubmittingRequest}
            onClick={() =>
              onServiceRequest({
                type: "ACTIVATE_SERVICE",
                serviceId: service.id,
              })
            }
          >
            <FormattedMessage
              id="UnavailableApplianceApp.ActivateServiceButton"
              defaultMessage="Add service"
            />
          </Button>
        ) : price &&
          canActivateServicesWithPayment &&
          tenantConfig.paymentsEnabled ? (
          <Button
            className="mx-auto px-5"
            variant="solid-primary"
            disabled={isSubmittingRequest}
            loading={isSubmittingRequest}
            onClick={() =>
              onServiceRequest({
                type: "ACTIVATE_BILLED_SERVICE",
                serviceId: service.id,
                priceId: price.id,
              })
            }
          >
            <span className="text-nowrap d-flex d-md-inline justify-content-center flex-wrap">
              <FormattedMessage
                id="UnavailableApplianceApp.ActivateBilledServiceButton"
                defaultMessage="Add service for {price}"
                values={{
                  price: (
                    <Price
                      amount={price.amount}
                      currency={price.currency}
                      interval={price.interval}
                      intervalCount={price.intervalCount}
                    />
                  ),
                }}
              />
            </span>
          </Button>
        ) : null}
      </Card>
    </div>
  );
};

export default UnavailableApplianceApp;
