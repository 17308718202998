/**
 * @generated SignedSource<<0313ffcfcab18ab9df40e026e4f2741b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActiveServicesTable_ServiceActivationsStatusFragment$data = ReadonlyArray<{
  readonly activatedAt: string;
  readonly appliance: {
    readonly name: string;
    readonly serial: string;
    readonly tags: ReadonlyArray<string>;
  };
  readonly id: string;
  readonly owned: boolean;
  readonly price: {
    readonly __typename: "Price";
  } | null;
  readonly service: {
    readonly name: string;
  };
  readonly " $fragmentType": "ActiveServicesTable_ServiceActivationsStatusFragment";
}>;
export type ActiveServicesTable_ServiceActivationsStatusFragment$key = ReadonlyArray<{
  readonly " $data"?: ActiveServicesTable_ServiceActivationsStatusFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ActiveServicesTable_ServiceActivationsStatusFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ActiveServicesTable_ServiceActivationsStatusFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "activatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "owned",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Service",
      "kind": "LinkedField",
      "name": "service",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Price",
      "kind": "LinkedField",
      "name": "price",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Appliance",
      "kind": "LinkedField",
      "name": "appliance",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "serial",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tags",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ServiceActivation",
  "abstractKey": null
};
})();

(node as any).hash = "99ed5980fa4d66a9036cce2597498688";

export default node;
