/**
 * @generated SignedSource<<f2f59d3af546b1a96abe0673350c6b77>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type Services_GetServices_Query$variables = {};
export type Services_GetServices_Query$data = {
  readonly services: ReadonlyArray<{
    readonly __typename: "Service";
    readonly prices: ReadonlyArray<{
      readonly __typename: "Price";
    }>;
  }> | null;
};
export type Services_GetServices_Query = {
  response: Services_GetServices_Query$data;
  variables: Services_GetServices_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "Services_GetServices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "Services_GetServices_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Service",
        "kind": "LinkedField",
        "name": "services",
        "plural": true,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Price",
            "kind": "LinkedField",
            "name": "prices",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7a3ae2e7f3a24ac810251734f2c6ed5d",
    "id": null,
    "metadata": {},
    "name": "Services_GetServices_Query",
    "operationKind": "query",
    "text": "query Services_GetServices_Query {\n  services {\n    __typename\n    prices {\n      __typename\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "0ac84fa1f8f22a9a619fff0dfebe07a6";

export default node;
