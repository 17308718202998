import { useState } from "react";
import { FormattedMessage } from "react-intl";

import Button from "components/Button";
import CookiePolicyModal from "components/CookiePolicyModal";
import PrivacyPolicyModal from "components/PrivacyPolicyModal";
import TermsAndConditionsModal from "components/TermsAndConditionsModal";

import * as images from "assets/images";

const AppFooter = () => {
  const [showCookiePolicyModal, setShowCookiePolicyModal] = useState(false);
  const [showPrivacyPolicyModal, setShowPrivacyPolicyModal] = useState(false);
  const [
    showTermsAndConditionsModal,
    setShowTermsAndConditionsModal,
  ] = useState(false);

  return (
    <div className="footer d-flex-column d-xl-flex justify-content-between align-items-center gap-3 p-2">
      <div className="text-center">
        <img src={images.footerImg} className="p-2 m-auto" />
      </div>
      <div className="text-center">
        <Button
          variant="text-primary"
          onClick={() => setShowTermsAndConditionsModal(true)}
        >
          <small>
            <FormattedMessage
              id="components.AppFooter.termsAndConditions.link"
              defaultMessage="Terms & Conditions"
            />
          </small>
        </Button>
        <Button
          variant="text-primary"
          onClick={() => setShowPrivacyPolicyModal(true)}
        >
          <small>
            <FormattedMessage
              id="components.AppFooter.privacyPolicy.link"
              defaultMessage="Privacy Policy"
            />
          </small>
        </Button>
        <Button
          variant="text-primary"
          onClick={() => setShowCookiePolicyModal(true)}
        >
          <small>
            <FormattedMessage
              id="components.AppFooter.cookiePolicy.link"
              defaultMessage="Cookie Policy"
            />
          </small>
        </Button>
      </div>
      <div className="text-center p-2">
        <span className="text-primary">&copy; 2023 Telecom Italia - Partita IVA 00488410010</span>
      </div>
      {showCookiePolicyModal && (
        <CookiePolicyModal onClose={() => setShowCookiePolicyModal(false)} />
      )}
      {showPrivacyPolicyModal && (
        <PrivacyPolicyModal onClose={() => setShowPrivacyPolicyModal(false)} />
      )}
      {showTermsAndConditionsModal && (
        <TermsAndConditionsModal
          onClose={() => setShowTermsAndConditionsModal(false)}
        />
      )}
    </div>
  );
};

export default AppFooter;
